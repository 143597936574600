import { COLORS, ensureColorHasHashtag } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import Helmet from "react-helmet";
import { View, Image } from "react-native-web";
import { ColoredHeader } from "./ColoredHeader";
import { StyledText } from "./StyledText";
import { Org, OrgSettings } from "@ollie-sports/models";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ReactNode } from "react";

export function OrgUnathenticatedPageHeader(p: { org: Org; orgSettings: OrgSettings; children: ReactNode }) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <View style={{ flex: 1, alignItems: "center", paddingBottom: 30, backgroundColor: COLORS.white }}>
      <ColoredHeader
        bgColor={p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : undefined}
        theme="short"
        title={p.org.shortName}
      />
      <View style={{ padding: mobileDevice ? 16 : 30, width: mobileDevice ? "100%" : undefined }}>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          {p.org.logoUri ? (
            <Image
              source={{ uri: p.org.logoUri, width: 40, height: 40 }}
              resizeMode="contain"
              style={{ marginRight: mobileDevice ? 0 : 16, ...{ order: mobileDevice ? 1 : 0 } }}
            />
          ) : null}
          <StyledText style={{ fontSize: 32, flex: 1 }}>{p.org.name}</StyledText>
        </View>
        {p.children}
      </View>
    </View>
  );
}
