import { useEffect, useReducer, useRef, useState } from "react";
import { useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import React from "react";
import { COLORS } from "../theme/COLORS";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { StyleSheet, TextInput, TouchableOpacity, View } from "react-native-web";
import { StyledText } from "./StyledText";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  Token
} from "@stripe/stripe-js";
import { getBifrost } from "../services/bifrost.service";
import { Coupon, CouponType, OrgCoupon, OrgCouponUseType, OrgId } from "@ollie-sports/models";
import ClearIcon from "@material-ui/icons/Clear";
import { translate } from "@ollie-sports/i18n";
import { StripePricingInfo, api, compute, formatMoneyValue } from "@ollie-sports/core";
import { useDidUpdateEffect } from "../hooks/useDidUpdateEffect";
import { useImmutableState } from "../utils/useImmutableState";
import { CenteredLoader } from "./CenteredLoader";

// Custom styling can be passed to options when creating an Element.

export interface CardFormHandle {
  submitForm: (event: any) => Promise<void>;
}

export function getDiscountedAmountStripe(p: { appliedCoupon?: Coupon; pricingInfo: StripePricingInfo }) {
  if (!p.appliedCoupon) {
    return 0;
  }
  switch (p.appliedCoupon.type) {
    case CouponType.amountOff:
      return Math.min(p.pricingInfo.numberCents, p.appliedCoupon.amountOff);
    case CouponType.percentOff:
      if (p.appliedCoupon.percentOff > 0 && p.appliedCoupon.percentOff <= 1) {
        return p.appliedCoupon.percentOff * p.pricingInfo.numberCents;
      } else {
        return 0;
      }
    case CouponType.customAmount:
      return p.pricingInfo.numberCents - p.appliedCoupon.customAmount;
  }
}

export function calculateFeesStripe(p: { appliedCoupon?: Coupon; pricingInfo: StripePricingInfo }) {
  if (!p.pricingInfo.chargeStripeFees) {
    return 0;
  }
  const costAfterDiscount = getCostAfterDiscountStripe(p);
  return CalculatePaymentFees({ chargeAmount: costAfterDiscount, applicationFeeAmount: p.pricingInfo.applicationFeeAmount });
}

export function getCostAfterDiscountStripe(p: { appliedCoupon?: Coupon; pricingInfo: StripePricingInfo }) {
  return p.pricingInfo.numberCents - getDiscountedAmountStripe(p);
}

export function getTotalAmountToChargeStripe(p: { appliedCoupon?: Coupon; pricingInfo: StripePricingInfo }) {
  return getCostAfterDiscountStripe(p) + calculateFeesStripe(p);
}

export function CalculatePaymentFees(p: { chargeAmount: number; applicationFeeAmount?: number }) {
  if (p.chargeAmount === 0) {
    return 0;
  }
  const totalToCharge = (p.chargeAmount + (p.applicationFeeAmount ?? 0) + 30) / (1 - 0.029);
  return Math.round(totalToCharge - p.chargeAmount);
}

export function CardForm(p: {
  onSubmit: (p: { token: Token; coupon?: Coupon; email?: string; amountToCharge: number; discount?: number }) => Promise<void>;
  buttonTitle?: string;
  defaultName?: string;
  askForEmail?: boolean;
  initialCoupon?: Coupon | null;
  pricingInfo: StripePricingInfo;
  isButtonDisabled?: boolean;
  locale?: string;
  buttonColor?: string;
  onSubmitFree: (couponCode?: string) => Promise<void>;
}) {
  const [, setError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [isProccessing, setIsProccessing] = useState(false);

  const [name, setName] = useState(p.defaultName ?? "");
  const [email, setEmail] = useState("");
  const [cardNumberEvent, setCardNumberEvent] = useState<StripeCardNumberElementChangeEvent>();
  const [cardExpiryEvent, setCardExpiryEvent] = useState<StripeCardExpiryElementChangeEvent>();
  const [cardCVCEvent, setCardCVCEvent] = useState<StripeCardCvcElementChangeEvent>();
  const [zip, setZip] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState<Coupon>();

  useEffect(() => {
    if (p.initialCoupon) {
      if (p.initialCoupon.type !== CouponType.customAmount && p.pricingInfo.specialString) {
        setCouponError("This coupon is not eligible during the current special");
      } else {
        setAppliedCoupon(p.initialCoupon);
      }
    }
  }, [!!p.initialCoupon]);

  const isAmountGreaterThan50Cents = getCostAfterDiscountStripe({ appliedCoupon, pricingInfo: p.pricingInfo }) > 50;
  const isFree = getCostAfterDiscountStripe({ appliedCoupon, pricingInfo: p.pricingInfo }) === 0;

  // Handle form submission.
  const handleSubmit = async (event: any) => {
    setIsProccessing(true);
    event.preventDefault();
    if (isFree) {
      await p.onSubmitFree(appliedCoupon?.id);
      setIsProccessing(false);
      return;
    }
    if (!elements || !stripe) {
      return;
    }
    try {
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement) {
        const result = await stripe.createToken(cardElement, {
          name,
          address_zip: zip
        });
        if (result && result.error) {
          // Inform the user if there was an error.
          setError(result.error.message ?? "There was an error proccessing the payment. Please try again.");
        } else {
          setError("");
          if (result.token) {
            await p.onSubmit({
              token: result.token,
              coupon: appliedCoupon,
              email: p.askForEmail ? email : undefined,
              amountToCharge: getTotalAmountToChargeStripe({ appliedCoupon, pricingInfo: p.pricingInfo }),
              discount: getDiscountedAmountStripe({ appliedCoupon, pricingInfo: p.pricingInfo })
            });
          }
        }
      }
    } catch (e) {
      //DO SOMETHING
    }
    setIsProccessing(false);
  };

  const isFormComplete =
    !!name &&
    !!cardNumberEvent &&
    cardNumberEvent.complete &&
    !!cardExpiryEvent &&
    cardExpiryEvent.complete &&
    !!cardCVCEvent &&
    cardCVCEvent.complete &&
    (!!email || !p.askForEmail) &&
    !!zip &&
    zip.length === 5;
  const isButtonDisabled = (!isFormComplete || isProccessing || p.isButtonDisabled || !isAmountGreaterThan50Cents) && !isFree;

  return (
    <form onSubmit={handleSubmit}>
      <View style={{ margin: 16 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledText style={styles.label}>
              {translate({ defaultMessage: "Name on Card", description: "For entering credit card info" })}
            </StyledText>
            <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
              <TextInput
                placeholder={translate({ defaultMessage: "Enter Your Name" })}
                placeholderTextColor={"#6A6A6A"}
                style={{ fontFamily: "sans-serif", outline: "none" } as any}
                value={name}
                onChangeText={newName => {
                  setName(newName);
                }}
              />
            </View>
          </Grid>
          <Grid item xs={12}>
            <StyledText style={styles.label}>
              {translate({
                defaultMessage: "Credit Card Number",
                description: "For entering credit card info"
              })}
            </StyledText>
            <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
              <CardNumberElement
                options={{ placeholder: "0000 0000 0000 0000" }}
                onChange={e => {
                  setCardNumberEvent(e);
                }}
              />
            </View>
          </Grid>
          <Grid item xs={6}>
            <StyledText style={styles.label}>
              {translate({ defaultMessage: "Expiration", description: "For entering credit card info" })}
            </StyledText>
            <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
              <CardExpiryElement
                options={{ placeholder: "MM / YY" }}
                onChange={e => {
                  setCardExpiryEvent(e);
                }}
              />
            </View>
          </Grid>
          <Grid item xs={6}>
            <StyledText style={styles.label}>
              {translate({
                defaultMessage: "CVC",
                description: "Security codee for entering credit card info"
              })}
            </StyledText>
            <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
              <CardCvcElement
                options={{ placeholder: "000" }}
                onChange={e => {
                  setCardCVCEvent(e);
                }}
              />
            </View>
          </Grid>
          {p.askForEmail ? (
            <Grid item xs={12}>
              <StyledText style={styles.label}>{translate.common.Email}</StyledText>
              <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
                <TextInput
                  placeholderTextColor={"#6A6A6A"}
                  style={{ fontFamily: "sans-serif", outline: "none" } as any}
                  value={email}
                  onChangeText={newEmail => {
                    setEmail(newEmail);
                  }}
                />
              </View>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <StyledText style={styles.label}>{translate({ defaultMessage: "Billing Zip Code" })}</StyledText>
            <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
              <TextInput
                placeholder="00000"
                placeholderTextColor={"#6A6A6A"}
                style={{ fontFamily: "sans-serif", outline: "none" } as any}
                keyboardType="number-pad"
                value={zip}
                onChangeText={newZip => {
                  if (newZip === "" || (Number.parseInt(newZip) && newZip.length <= 5)) {
                    setZip(newZip);
                  }
                }}
              />
            </View>
          </Grid>
          <Grid item xs={6}>
            <View>
              <StyledText style={styles.label}>{`${translate({ defaultMessage: "Coupon Code" })} (${
                translate.common.Optional
              })`}</StyledText>
              <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
                <TextInput
                  placeholder={translate({ defaultMessage: "Enter Coupon" })}
                  placeholderTextColor={"#6A6A6A"}
                  style={{ fontFamily: "sans-serif", outline: "none" } as any}
                  value={coupon}
                  onChangeText={newCoupon => {
                    setCoupon(newCoupon.toUpperCase().replace(" ", ""));
                  }}
                />
              </View>
              <Button
                style={{ color: coupon ? COLORS.blue : COLORS.grey_33 }}
                disabled={!coupon}
                onClick={async () => {
                  const { data: fetchedCoupon } = await getBifrost().coupon__server__getCoupon.fetchServer({
                    couponId: coupon
                  });
                  if (fetchedCoupon) {
                    if (fetchedCoupon.expirationDateMS && Date.now() > fetchedCoupon.expirationDateMS) {
                      setCouponError(translate({ defaultMessage: "Coupon has expired" }));
                    } else if (
                      fetchedCoupon.useLimit &&
                      fetchedCoupon.numberTimesUsed &&
                      fetchedCoupon.numberTimesUsed >= fetchedCoupon.useLimit
                    ) {
                      setCouponError(translate({ defaultMessage: "Coupon limit has been reached" }));
                    } else if (fetchedCoupon.type !== CouponType.customAmount && p.pricingInfo.specialString) {
                      setCouponError(
                        translate({
                          defaultMessage: "This coupon is not eligible during the current special"
                        })
                      );
                    } else {
                      setCouponError("");
                      setAppliedCoupon(fetchedCoupon);
                    }
                  } else {
                    setCouponError(
                      translate({
                        defaultMessage: "Coupon not found"
                      })
                    );
                  }
                }}
              >
                {translate.common.Apply}
              </Button>
              {couponError ? <StyledText style={{ color: COLORS.red, marginTop: 8 }}>{couponError}</StyledText> : null}
              {appliedCoupon ? (
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setAppliedCoupon(undefined);
                    }}
                  >
                    <ClearIcon style={{ color: COLORS.yellow }} fontSize={"small"} />
                  </TouchableOpacity>
                  <StyledText style={{ color: COLORS.yellow }}>{appliedCoupon.id}</StyledText>
                </View>
              ) : null}
            </View>
          </Grid>
          <Grid item xs={6} style={{ color: COLORS.black }}>
            <StyledText>{translate({ defaultMessage: "Subtotal", description: "For payments" }).toUpperCase()}</StyledText>
          </Grid>
          <Grid item xs={6} style={{ color: COLORS.black }}>
            <StyledText>{`$${formatMoneyValue(p.pricingInfo.numberCents)}`}</StyledText>
          </Grid>
          {appliedCoupon ? (
            <>
              <Grid item xs={6}>
                <StyledText style={{ color: COLORS.yellow }}>
                  {translate({ defaultMessage: "Discount", description: "For payments" }).toUpperCase()}
                </StyledText>
              </Grid>
              <Grid item xs={6}>
                <StyledText style={{ color: COLORS.yellow }}>{`-$${formatMoneyValue(
                  getDiscountedAmountStripe({ appliedCoupon, pricingInfo: p.pricingInfo })
                )}`}</StyledText>
              </Grid>
            </>
          ) : null}
          <Grid item xs={6} style={{ color: COLORS.red }}>
            <StyledText>{translate({ defaultMessage: "Fees", description: "For payments" }).toUpperCase()}</StyledText>
          </Grid>
          <Grid item xs={6} style={{ color: COLORS.red }}>
            <StyledText>{`$${formatMoneyValue(calculateFeesStripe({ appliedCoupon, pricingInfo: p.pricingInfo }))}`}</StyledText>
          </Grid>
          <Grid item xs={6}>
            <StyledText style={{ color: COLORS.green }}>{translate.common.Total.toUpperCase()}</StyledText>
          </Grid>
          <Grid item xs={6} style={{ color: COLORS.green }}>
            <StyledText>{`$${formatMoneyValue(
              getCostAfterDiscountStripe({ appliedCoupon, pricingInfo: p.pricingInfo }) +
                calculateFeesStripe({ appliedCoupon, pricingInfo: p.pricingInfo })
            )}`}</StyledText>
          </Grid>

          {!isAmountGreaterThan50Cents && !isFree ? (
            <StyledText style={{ marginLeft: 16, color: COLORS.red }}>
              {translate({
                defaultMessage: "We cannot process transactions under $0.50 USD at this time."
              })}
            </StyledText>
          ) : null}
          {isFree ? (
            <StyledText style={{ marginLeft: 16 }}>
              {translate({
                defaultMessage: "Note: Your card will not be charged."
              })}
            </StyledText>
          ) : null}
          <Grid item xs={12}>
            <Button
              fullWidth
              style={{
                backgroundColor: !isButtonDisabled ? p.buttonColor ?? COLORS.gold : COLORS.grey_33,
                color: COLORS.white,
                borderWidth: 0,
                borderRadius: 100
              }}
              variant="outlined"
              type="submit"
              disabled={isButtonDisabled}
            >
              {isProccessing ? (
                <>
                  {isFree ? translate({ defaultMessage: "Processing" }) : translate({ defaultMessage: "Processing Payment" })}
                  <CircularProgress size={14} style={{ marginLeft: 12, color: COLORS.white }} />
                </>
              ) : (
                `${p.buttonTitle ?? translate.common.Submit}`
              )}
            </Button>
          </Grid>
        </Grid>
      </View>
    </form>
  );
}

export function CardFormV2(p: {
  initialName: string;
  onCardDetailsChange: (e: { cardTokenId?: string; coupon?: OrgCoupon }) => void;
  refreshFlipFlop?: boolean;
  orgId: OrgId;
  orgCouponUseType: OrgCouponUseType;
}) {
  const [cardTokenId, setCardTokenId] = useState<null | string>(null);
  const [form, setForm] = useImmutableState({
    number: null as null | StripeCardNumberElementChangeEvent,
    expiry: null as null | StripeCardExpiryElementChangeEvent,
    cvc: null as null | StripeCardCvcElementChangeEvent,
    name: p.initialName,
    zip: "",
    couponText: "",
    appliedCoupon: null as null | OrgCoupon,
    couponErrorMsg: "",
    cardErrorMsg: ""
  });

  const [refreshTokenFlipFlop, toggleRefreshTokenFlipFlop] = useReducer(a => !a, true);

  //The initial name data sometimes trickles in. Gotta do something a bit weird to get it
  const shouldShadowInitialName = useRef(true);
  useEffect(() => {
    if (shouldShadowInitialName.current) {
      setForm({ name: p.initialName });
    }
  }, [p.initialName]);

  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe()!;
  const elements = useElements()!;

  const formIsValid = !!(form.number?.complete && form.expiry?.complete && form.cvc?.complete && form.zip.length === 5);

  const tokenFetchCount = useRef(0);
  useDidUpdateEffect(() => {
    const currentEffect = ++tokenFetchCount.current;
    if (formIsValid) {
      const cardElement = elements.getElement(CardNumberElement);
      if (!cardElement) {
        throw new Error("Unable to find card element");
      }

      setIsProcessing(true);
      setCardTokenId(null);

      stripe
        .createToken(cardElement, {
          name: form.name
        })
        .finally(() => {
          setIsProcessing(false);
        })
        .then(result => {
          if (currentEffect !== tokenFetchCount.current) {
            return; //User must have superseded this invocation
          }

          if (result.error) {
            console.error(result.error);
            getBifrost().olliePipe__server__sendOlliePipeEvent.fetchServer({
              type: "metric-tryout-fetch-card-token-failed-1",
              payload: result.error
            });
            setForm({ cardErrorMsg: translate({ defaultMessage: "Unable to retrieve credit card info" }) });
          } else {
            setCardTokenId(result.token.id);
          }
        })
        .catch(e => {
          if (currentEffect !== tokenFetchCount.current) {
            return; //User must have superseded this invocation
          }

          setCardTokenId(null);
          setForm({
            cardErrorMsg: translate({ defaultMessage: "Unable to verify card. Please enter a new card and try again" })
          });
          console.error(e);
          getBifrost().olliePipe__server__sendOlliePipeEvent.fetchServer({
            type: "metric-tryout-fetch-card-token-failed-2",
            payload: e
          });
        });
    } else {
      setCardTokenId(null);
    }
  }, [formIsValid, refreshTokenFlipFlop, p.refreshFlipFlop, api.common__hashObject({ obj: form.appliedCoupon || {} })]);

  useDidUpdateEffect(() => {
    if (cardTokenId) {
      p.onCardDetailsChange({ cardTokenId: cardTokenId, coupon: form.appliedCoupon ?? undefined });
    } else {
      p.onCardDetailsChange({ cardTokenId: "", coupon: form.appliedCoupon ?? undefined });
    }
  }, [cardTokenId, !!form.appliedCoupon]);

  const couponFetchCount = useRef(0);
  function applyCoupon() {
    const currentCouponFetch = ++couponFetchCount.current;
    getBifrost()
      .orgCoupon__server__getOrgCouponNoAuth.fetchServer({
        code: form.couponText,
        orgId: p.orgId,
        useType: p.orgCouponUseType
      })
      .then(({ data: fetchedCoupon }) => {
        if (currentCouponFetch < couponFetchCount.current) {
          return; //Superseded
        }

        if (fetchedCoupon) {
          if (fetchedCoupon.expirationDateMS && Date.now() > fetchedCoupon.expirationDateMS) {
            setForm({ couponErrorMsg: translate({ defaultMessage: "Coupon has expired" }) });
          } else if (
            fetchedCoupon.useLimit &&
            fetchedCoupon.numberTimesUsed &&
            fetchedCoupon.numberTimesUsed >= fetchedCoupon.useLimit
          ) {
            setForm({ couponErrorMsg: translate({ defaultMessage: "Coupon limit has been reached" }) });
          } else {
            setForm({ couponErrorMsg: "", appliedCoupon: fetchedCoupon });
          }
        } else {
          setForm({ couponErrorMsg: translate({ defaultMessage: "Coupon not found" }) });
        }
      });
  }

  const inputStyle = {
    fontSize: "16px",
    paddingTop: 5,
    paddingBottom: 5
  };

  return (
    <Grid container spacing={3}>
      {isProcessing ? (
        <DelayedMount delay={500}>
          <CenteredLoader style={StyleSheet.absoluteFillObject} />
        </DelayedMount>
      ) : null}
      <Grid item xs={12}>
        <StyledText style={styles.label}>
          {translate({
            defaultMessage: "Cardholder Name"
          })}
        </StyledText>
        <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
          <TextInput
            placeholder="John Doe..."
            placeholderTextColor={"#6A6A6A"}
            style={{ fontFamily: "sans-serif", border: "none", outline: "none", fontSize: inputStyle.fontSize } as any}
            value={form.name}
            onChangeText={name => {
              shouldShadowInitialName.current = false;
              toggleRefreshTokenFlipFlop();
              setForm({ name });
            }}
          />
        </View>
      </Grid>
      <Grid item xs={12}>
        <StyledText style={styles.label}>
          {translate({
            defaultMessage: "Credit Card Number",
            description: "For entering credit card info"
          })}
        </StyledText>
        <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
          <CardNumberElement
            onBlur={toggleRefreshTokenFlipFlop}
            options={{
              placeholder: "0000 0000 0000 0000",
              style: { base: inputStyle }
            }}
            onChange={e => {
              setForm({ number: e });
            }}
          />
        </View>
      </Grid>
      <Grid item xs={6}>
        <StyledText style={styles.label}>
          {translate({ defaultMessage: "Expiration", description: "For entering credit card info" })}
        </StyledText>
        <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
          <CardExpiryElement
            onBlur={toggleRefreshTokenFlipFlop}
            options={{ placeholder: "MM / YY", style: { base: inputStyle } }}
            onChange={e => {
              setForm({ expiry: e });
            }}
          />
        </View>
      </Grid>
      <Grid item xs={6}>
        <StyledText style={styles.label}>
          {translate({
            defaultMessage: "CVC",
            description: "Security codee for entering credit card info"
          })}
        </StyledText>
        <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
          <CardCvcElement
            onBlur={toggleRefreshTokenFlipFlop}
            options={{ placeholder: "000", style: { base: inputStyle } }}
            onChange={e => {
              setForm({ cvc: e });
            }}
          />
        </View>
      </Grid>
      {form.cardErrorMsg ? <StyledText style={{ color: COLORS.red, marginTop: 8 }}>{form.cardErrorMsg}</StyledText> : null}
      <Grid item xs={6}>
        <StyledText style={styles.label}>{translate({ defaultMessage: "Billing Zip Code" })}</StyledText>
        <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
          <TextInput
            placeholder="00000"
            placeholderTextColor={"#6A6A6A"}
            style={{ fontFamily: "sans-serif", outline: "none", ...inputStyle } as any}
            keyboardType="number-pad"
            value={form.zip}
            onChangeText={newZip => {
              if (newZip === "" || (Number.parseInt(newZip) && newZip.length <= 5)) {
                setForm({ zip: newZip });
                toggleRefreshTokenFlipFlop();
              }
            }}
          />
        </View>
      </Grid>
      <Grid item xs={6}>
        <View>
          <StyledText style={styles.label}>{`${translate({ defaultMessage: "Coupon Code" })} (${
            translate.common.Optional
          })`}</StyledText>
          <View style={{ borderBottomWidth: 1, borderColor: COLORS.grey_33, paddingVertical: 4 }}>
            <input
              placeholder={translate({ defaultMessage: "Enter Coupon" })}
              type="text"
              value={form.couponText}
              style={{
                fontFamily: "sans-serif",
                outline: "none",
                background: "none",
                border: "none",
                ...inputStyle
              }}
              onChange={e => {
                setForm({ couponText: e.target.value.toUpperCase().replace(" ", "") });
              }}
            />
          </View>
          <Button
            style={{ color: form.couponText ? COLORS.blue : COLORS.grey_33 }}
            disabled={!form.couponText}
            onClick={async () => {
              applyCoupon();
            }}
          >
            {translate.common.Apply}
          </Button>
          {form.couponErrorMsg ? (
            <StyledText style={{ color: COLORS.red, marginTop: 8 }}>{form.couponErrorMsg}</StyledText>
          ) : null}
          {form.appliedCoupon ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  setForm({
                    appliedCoupon: null,
                    couponText: ""
                  });
                }}
              >
                <ClearIcon style={{ color: COLORS.yellow }} fontSize={"small"} />
              </TouchableOpacity>
              <StyledText style={{ color: COLORS.yellow }}>{form.appliedCoupon.code}</StyledText>
            </View>
          ) : null}
        </View>
      </Grid>
    </Grid>
  );
}

export function CardFormV2Totals(p: { pricingInfo: StripePricingInfo; appliedCoupon?: OrgCoupon }) {
  const subtotal = p.pricingInfo.numberCents;
  const discountAmount = compute.payments.getDiscountedAmount({
    pricingInfo: p.pricingInfo,
    appliedCoupon: p.appliedCoupon
  });
  const feeAmount = compute.payments.calculateFees({
    pricingInfo: p.pricingInfo,
    appliedCoupon: p.appliedCoupon
  });
  const totalAmount =
    compute.payments.getCostAfterDiscount({
      pricingInfo: p.pricingInfo,
      appliedCoupon: p.appliedCoupon
    }) + feeAmount;
  const isAmountGreaterThan50Cents = totalAmount > 50;
  const isFree = totalAmount === 0;
  return (
    <Grid container spacing={3} style={{ marginTop: 16 }}>
      <Grid item xs={6} style={{ color: COLORS.black }}>
        <StyledText>{translate({ defaultMessage: "Subtotal", description: "For payments" }).toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.black }}>
        <StyledText>{`$${formatMoneyValue(subtotal)}`}</StyledText>
      </Grid>
      {p.appliedCoupon ? (
        <>
          <Grid item xs={6}>
            <StyledText style={{ color: COLORS.yellow }}>
              {translate({ defaultMessage: "Discount", description: "For payments" }).toUpperCase()}
            </StyledText>
          </Grid>
          <Grid item xs={6}>
            <StyledText style={{ color: COLORS.yellow }}>{`-$${formatMoneyValue(discountAmount)}`}</StyledText>
          </Grid>
        </>
      ) : null}
      <Grid item xs={6} style={{ color: COLORS.red }}>
        <StyledText>{translate({ defaultMessage: "Fees", description: "For payments" }).toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.red }}>
        <StyledText>{`$${formatMoneyValue(feeAmount)}`}</StyledText>
      </Grid>
      <Grid item xs={6}>
        <StyledText style={{ color: COLORS.green }}>{translate.common.Total.toUpperCase()}</StyledText>
      </Grid>
      <Grid item xs={6} style={{ color: COLORS.green }}>
        <StyledText>{`$${formatMoneyValue(totalAmount)}`}</StyledText>
      </Grid>

      {!isAmountGreaterThan50Cents && !isFree ? (
        <StyledText style={{ marginLeft: 16, color: COLORS.red }}>
          {translate({
            defaultMessage: "We cannot process transactions under $0.50 USD at this time."
          })}
        </StyledText>
      ) : null}
      {isFree ? (
        <StyledText style={{ marginLeft: 16, marginTop: 16 }}>
          {translate({
            defaultMessage: "Note: Your card will not be charged."
          })}
        </StyledText>
      ) : null}
    </Grid>
  );
}

const styles = StyleSheet.create({
  label: { marginBottom: 4, fontSize: 14 }
});

function DelayedMount(p: { children: JSX.Element; delay: number }) {
  const [shouldMount, setShouldMount] = useState(false);
  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      isMounted && setShouldMount(true);
    }, p.delay);

    return () => {
      isMounted = false;
    };
  }, []);

  return shouldMount ? p.children : null;
}
