import React, { ReactNode, Fragment } from "react";

export function PressableAsterisksText(p: {
  children: string;
  className?: string;
  asterisksClassName?: string;
  onAsteriskPress: (asteriskText: string) => void;
}) {
  return (
    <span className={p.className}>
      {customizeAsterisks(p.children, str => (
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            p.onAsteriskPress(str);
          }}
          className={p.asterisksClassName}
        >
          {str}
        </a>
      ))}
    </span>
  );
}

export function customizeAsterisks(str: string, fn: (innerStr: string) => ReactNode) {
  // Split the string into parts based on asterisks
  const parts = str.split("*");

  // Map over the parts
  const nodes = parts.map((part, index) => {
    // If the part was surrounded by asterisks (i.e., it's at an odd index), pass it to the function
    if (index % 2 === 1) {
      return <Fragment key={index}>{fn(part)}</Fragment>;
    }
    // Otherwise, return the part as a string
    else {
      return <Fragment key={index}>{part}</Fragment>;
    }
  });

  // Return the array of nodes
  return <>{nodes}</>;
}
