import React, { FC } from "react";

interface LogoProps {
  variant?: "dark" | "white" | "ollie-o";
  style?: React.CSSProperties;
}

const Logo: FC<LogoProps> = p => {
  const src =
    p.variant === "dark"
      ? "/static/ollie-logo-right-dark-name.svg"
      : p.variant === "white"
      ? "/static/ollie-logo-all-white-with-name.svg"
      : p.variant === "ollie-o"
      ? "/static/ollie-o.svg"
      : "/static/ollie-logo-right-white-name.svg";

  return <img alt="Logo" style={{ width: 100, ...(p.style || {}) }} src={src} {...p} />;
};

export default Logo;
