import React from "react";
import { CircularProgress } from "@material-ui/core";
import { View, ViewStyle } from "react-native-web";

export function CenteredLoader(p: { style?: ViewStyle }) {
  return (
    <View style={[{ flex: 1, justifyContent: "center", alignItems: "center" }, p.style]}>
      <CircularProgress />
    </View>
  );
}
