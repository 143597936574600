import React, { CSSProperties } from "react";
import { COLORS } from "@ollie-sports/core";
import { makeStyles } from "@material-ui/core";
import { ShadowView } from "./ShadowView";

type TabRouteDef = {
  title: string;
  isFocused: boolean;
  onPress: () => void;
};

interface Props {
  tabs: TabRouteDef[];
  style?: any;
}

const useStyles = makeStyles({
  tabbar: {
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
});

export function TabBar(p: Props) {
  const classes = useStyles();
  const tabs = p.tabs.map((t, idx) => {
    return (
      <div key={idx} onClick={t.onPress} style={t.isFocused ? styles.focusedTab : styles.tab}>
        <span style={t.isFocused ? styles.focusedText : styles.tabText}>{t.title}</span>
      </div>
    );
  });

  const wrapperStyle = Object.assign({}, styles.wrapper, ShadowView.defaultStyle, p.style);
  return (
    <div className={classes.tabbar} style={wrapperStyle}>
      {tabs}
    </div>
  );
}

const styles: Record<string, CSSProperties> = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white"
  },
  tab: {
    cursor: "pointer",
    padding: "12px"
  },
  focusedTab: {
    cursor: "pointer",
    padding: "12px",
    borderBottom: `1px solid ${COLORS.green}`
  },
  tabText: {
    fontFamily: "Roboto Condensed"
  },
  focusedText: {
    fontFamily: "Roboto Condensed",
    color: COLORS.green
  }
};
