import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ViewStyle } from "react-native-web";
import { twMerge } from "tailwind-merge";

const useStyles = makeStyles(theme => ({
  container: (p: { durationMS: number; isExpanded: boolean }) => ({
    display: "grid",
    transition: `grid-template-rows ${p.durationMS}ms`,
    gridTemplateRows: p.isExpanded ? "1fr" : "0fr",
    overflow: "hidden", // Ensure overflow is hidden to contain the child elements
    width: "100%" // Ensure the container takes full width
  }),
  content: (p: { durationMS: number; isExpanded: boolean }) => ({
    minHeight: 0,
    alignSelf: "end",
    visibility: p.isExpanded ? "visible" : "hidden",
    transition: `visibility ${p.durationMS}ms`,
    overflow: "auto", // Allow content to scroll if it overflows
    width: "100%", // Ensure content width is responsive
    whiteSpace: "normal", // Ensure text wraps in normal fashion
    wordWrap: "break-word" // Ensure long words do not cause horizontal scrolling
  })
}));

export function Expando(p: {
  isExpanded: boolean;
  durationMS: number;
  style?: React.CSSProperties;
  contentClassName?: string;
  children: ReactNode;
}) {
  const styles = useStyles({ durationMS: p.durationMS, isExpanded: p.isExpanded });

  return (
    <div style={p.style} className={styles.container}>
      <div className={twMerge(styles.content, p.contentClassName)}>{p.children}</div>
    </div>
  );
}
