import React, { useState } from "react";
import { COLORS } from "@ollie-sports/core";
import { useDidUpdateEffect } from "../hooks/useDidUpdateEffect";

interface Props {
  size: number;
  accountInfo: {
    firstName: string;
    lastName: string;
    profileImageUriSmall?: string;
    profileImageUri?: string;
  };
  style?: any;
  innerStyle?: any;
}

export function ProfileCircle(p: Props) {
  const photoUri = p.size && p.size > 60 ? p.accountInfo.profileImageUri : p.accountInfo.profileImageUriSmall;
  const [hasErrored, setHasErrored] = useState(false);

  useDidUpdateEffect(() => {
    setHasErrored(false);
  }, [photoUri]);

  const photoStyle = {
    height: p.size || 38,
    width: p.size || 38,
    borderRadius: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const initials = (p.accountInfo.firstName[0] ?? "") + (p.accountInfo.lastName[0] ?? "");
  return (
    <div
      style={{
        ...{ height: p.size || 38, width: p.size || 38, borderRadius: 1000 },
        ...(styles.container || {}),
        ...(p.style || {})
      }}
    >
      {photoUri && !hasErrored ? (
        <img
          onError={() => {
            setHasErrored(true);
          }}
          src={photoUri}
          style={{
            ...photoStyle,
            ...(p.innerStyle || {})
          }}
          alt="profile"
        />
      ) : (
        <div
          style={{
            ...photoStyle,
            ...{
              color: "white",
              fontWeight: "bold",
              backgroundColor: COLORS.blue,
              fontSize: p.size * 0.5
            }
          }}
        >
          {initials ? initials : <>&nbsp;</>}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    borderRadius: 999,
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    color: COLORS.white,
    fontFamily: "bold"
  }
};
