import { ReactNode, useState } from "react";
import clsx from "clsx";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export function CoolerTabbedContent(p: {
  className?: string;
  useQueryParams?: boolean;
  initialTabKey?: string;
  onTabChange?: (newTabKey: string) => void;
  tabs: {
    key: string;
    labelIcon: JSX.Element;
    label: string;
    getContents: () => ReactNode;
  }[];
}) {
  if (!p.tabs.length) {
    throw new Error("Must provide at least one tab!");
  }

  const defaultTab = p.initialTabKey || p.tabs[0].key;
  const [searchParams, __] = useSearchParamsState<{ tab?: string }>();
  const [selectedTabState, setSelectedTabState] = useState(defaultTab);

  const location = useLocation();

  const selectedTab = (p.useQueryParams ? searchParams.tab : selectedTabState) || defaultTab;

  const tabs = p.tabs.map(a => ({
    label: a.label,
    isFocused: selectedTab === a.key,
    key: a.key,
    icon: a.labelIcon
  }));

  return (
    <div className={p.className}>
      <div className={"bg-gray-100 rounded-md shadow-md"}>
        <div>
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
            {tabs.map(tab => {
              const search = new URLSearchParams(location.search);
              search.set("tab", tab.key);
              const routerPath = `${location.pathname}?${search.toString()}${location.hash}`;
              return (
                <li key={tab.key}>
                  <Link
                    to={p.useQueryParams ? routerPath : "#"}
                    onClick={
                      p.useQueryParams
                        ? undefined
                        : e => {
                            e.preventDefault();
                            p.onTabChange?.(tab.key);
                            setSelectedTabState(tab.key);
                          }
                    }
                    className={clsx(
                      `px-5 rounded-t-md cursor-pointer inline-flex items-center justify-center p-4 group hover:text-blue-500`,
                      tab.isFocused && "bg-white text-blue-500"
                    )}
                  >
                    {tab.icon ? <div className="w-6 h-6 mr-2">{tab.icon}</div> : null}
                    {tab.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="p-4 bg-white rounded-b-md">{p.tabs.find(a => a.key === selectedTab)?.getContents() ?? null}</div>
      </div>
    </div>
  );
}
