import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import useIsMountedRef from "../hooks/useIsMountedRef";

export function WrappingInput(
  p: React.InputHTMLAttributes<HTMLInputElement> & {
    resizeCounter: number; //Increment this number to trigger a resize
    parentRef: React.RefObject<HTMLElement>;
  }
) {
  const sizerRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [width, setWidth] = useState<undefined | number>(undefined);
  const { parentRef, style, resizeCounter, ...rest } = p;

  const isMountedRef = useIsMountedRef();
  function doResize() {
    if (parentRef.current && sizerRef.current && inputRef.current) {
      const remainingSpace = parentRef.current.scrollWidth - sizerRef.current.offsetLeft;
      const sizerWidth = sizerRef.current.scrollWidth + getHorizontalPadding(inputRef.current);

      let newWidth: number;
      if (sizerWidth > remainingSpace) {
        if (inputRef.current.offsetLeft === 0) {
          newWidth = parentRef.current.scrollWidth;
        } else {
          newWidth = sizerWidth + 6;
        }
      } else {
        newWidth = remainingSpace - 1;
      }

      isMountedRef.current && setWidth(newWidth);
    }
  }

  const hasDoneInitialResize = useRef(false);
  useLayoutEffect(() => {
    if (!hasDoneInitialResize.current) {
      hasDoneInitialResize.current = true;
      setTimeout(doResize, 50);
    } else {
      doResize();
    }
  }, [p.value || p.placeholder, resizeCounter, width]);

  return (
    <>
      <input
        ref={sizerRef}
        size={1}
        style={{
          ...(style || {}),
          boxSizing: "border-box",
          width: 0.1,
          zIndex: -1,
          visibility: "hidden",
          pointerEvents: "none",
          height: 0,
          padding: 0
        }}
        {...rest}
        value={p.value || p.placeholder}
      />
      <input ref={inputRef} style={{ width, boxSizing: "border-box", ...(style || {}) }} {...rest} />
    </>
  );
}

function getHorizontalPadding(elm: HTMLElement) {
  const style = getComputedStyle(elm);
  return parseInt(style.getPropertyValue("padding-left")) + parseInt(style.getPropertyValue("padding-right"));
}
