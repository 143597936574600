import { CalendarDaysIcon, ChartBarIcon, ClipboardIcon, UserPlusIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TEAM_PERMISSIONS, Team__Account } from "@ollie-sports/models";
import { View } from "react-native-web";

export function PermissionRow(p: { teamAccount: Team__Account; style?: any }) {
  return (
    <View style={[{ flexDirection: "row" }, p.style]}>
      <PermissionIcon
        permission={TEAM_PERMISSIONS.manageEvents}
        hasPermission={!!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageEvents]}
        hideMarginLeft={true}
      />
      <PermissionIcon
        permission={TEAM_PERMISSIONS.manageRolesAndPermissions}
        hasPermission={!!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRolesAndPermissions]}
        hideMarginLeft={!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageEvents]}
      />
      <PermissionIcon
        permission={TEAM_PERMISSIONS.manageRoster}
        hasPermission={!!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRoster]}
        hideMarginLeft={
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageEvents] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRolesAndPermissions]
        }
      />
      <PermissionIcon
        permission={TEAM_PERMISSIONS.recordStats}
        hasPermission={!!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.recordStats]}
        hideMarginLeft={
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageEvents] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRolesAndPermissions] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRoster]
        }
      />
      <PermissionIcon
        permission={TEAM_PERMISSIONS.viewIndividualStats}
        hasPermission={!!p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.viewIndividualStats]}
        hideMarginLeft={
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageEvents] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRolesAndPermissions] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.manageRoster] &&
          !p.teamAccount.additionalPermissions?.[TEAM_PERMISSIONS.recordStats]
        }
      />
    </View>
  );
}

function PermissionIcon(p: { permission: TEAM_PERMISSIONS; hasPermission: boolean; hideMarginLeft?: boolean }) {
  if (!p.hasPermission) {
    return null;
  }
  const icon =
    p.permission === TEAM_PERMISSIONS.manageEvents ? (
      <CalendarDaysIcon />
    ) : p.permission === TEAM_PERMISSIONS.recordStats ? (
      <ChartBarIcon />
    ) : p.permission === TEAM_PERMISSIONS.manageRolesAndPermissions ? (
      <ClipboardIcon />
    ) : p.permission === TEAM_PERMISSIONS.manageRoster ? (
      <UserPlusIcon />
    ) : p.permission === TEAM_PERMISSIONS.viewIndividualStats ? (
      <MagnifyingGlassIcon />
    ) : null;
  return <View style={{ width: 20, height: 20, marginLeft: p.hideMarginLeft ? 0 : 2 }}>{icon}</View>;
}
