import React, { FC, ReactNode, useEffect, useRef, useState, CSSProperties } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Collapse,
  Container,
  Drawer,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  TextField,
  useTheme
} from "@material-ui/core";
import { Theme } from "src/theme";
import { StyledText } from "./StyledText";
import { TouchableOpacity, View } from "react-native-web";
import { GENDERS, Org, Team } from "@ollie-sports/models";
import { COLORS, ObjectKeys } from "@ollie-sports/core";
import _ from "lodash";
import { Close, ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import CheckBoxSelectedIcon from "@material-ui/icons/CheckBox";
import CheckBoxUnselectedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { ShadowView } from "./ShadowView";
import { useArrayFilteredBySearch } from "../utils/fuzzyTextSearchFaux";
import { BoldMatchText } from "./BoldMatchText";
import FilterIcon from "@material-ui/icons/FilterList";
import { getBifrost } from "../services/bifrost.service";
import { useDebouncedFn } from "../utils/hooks/useDebouncedFn";

const APPLY_BUTTON_HEIGHT = 75;
const DRAWER_WIDTH = 400;
const STANDALONE_TEAM_KEY = "standalone";

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  searchField: {
    "& .MuiInput-underline:before": {
      content: "",
      display: "none"
    }
  },
  selectAllToggleButtons: {
    fontSize: 16,
    padding: "16px 0",
    color: COLORS.blue,
    cursor: "pointer",
    marginRight: 16,
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

type TeamWithSquad = Team & { teamIdWithSquad: string; teamNameWithSquad: string; squad?: "a" | "b" | "c" };

export function TeamsWithSquadSelector(p: {
  allTeamsWithSquad: TeamWithSquad[];
  selectedTeamsWithSquad: TeamWithSquad[];
  onSelectedTeamsWithSquad: (a: TeamWithSquad[]) => void;
  style?: CSSProperties;
}) {
  //Helper Vars
  const classes = useStyles();
  const theme = useTheme();
  const orgIds = _(p.allTeamsWithSquad)
    .map(t => t.orgId)
    .filter((a): a is string => !!a)
    .uniq()
    .value();
  const teamsWithoutOrg = p.allTeamsWithSquad.filter(t => !t.orgId);

  //Init state & refs
  const searchElmRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const getTempSelected = (teams: TeamWithSquad[]) => {
    return teams.reduce((acc, t) => {
      acc[t.teamIdWithSquad] = true;
      return acc;
    }, {} as Record<string, boolean>);
  };

  const [tempSelectedTeamIdsWithSquad, setTempSelectedTeamIdsWithSquad] = useState(getTempSelected(p.selectedTeamsWithSquad));

  const teamIdsHash = p.selectedTeamsWithSquad
    .map(t => t.teamIdWithSquad)
    .sort()
    .join();
  useEffect(() => {
    if (
      teamIdsHash !==
      Object.keys(tempSelectedTeamIdsWithSquad)
        .sort()
        .join()
    )
      setTempSelectedTeamIdsWithSquad(getTempSelected(p.selectedTeamsWithSquad));
  }, [teamIdsHash]);

  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);

  const [selectedFilterOrgIds, setSelectedFilterOrgIds] = useState<{ [k in string]?: boolean }>({});
  const [selectedFilterGenders, setSelectedFilterGenders] = useState<{ [k in GENDERS]?: boolean }>({});
  const [filterSearchTerm, setFilterSearchTermRaw] = useState("");

  const setFilterSearchTerm = useDebouncedFn({
    fn: setFilterSearchTermRaw,
    deps: [],
    ms: 50
  });

  //Interaction Handlers
  function triggerApplyAndClose() {
    setIsOpen(false);
    setSelectedFilterGenders({});
    setSelectedFilterOrgIds({});
    if (searchElmRef.current) {
      searchElmRef.current.value = "";
    }
    setFilterSearchTerm("");

    const newSelectedTeams = Object.keys(tempSelectedTeamIdsWithSquad)
      .filter(teamIdWithSquad => tempSelectedTeamIdsWithSquad[teamIdWithSquad])
      .map(teamIdWithSquad => {
        return p.allTeamsWithSquad.find(t => t.teamIdWithSquad === teamIdWithSquad);
      })
      .filter((a): a is TeamWithSquad => !!a);

    if (
      newSelectedTeams.length &&
      !_.isEqual(newSelectedTeams.map(t => t.teamIdWithSquad).sort(), p.selectedTeamsWithSquad.map(t => t.teamIdWithSquad).sort())
    ) {
      p.onSelectedTeamsWithSquad(newSelectedTeams);
    } else {
      setTempSelectedTeamIdsWithSquad(getTempSelected(p.selectedTeamsWithSquad));
    }
  }

  //Data fetching
  const { data: rawOrgs } = getBifrost().org__client__getOrgs.useClient({ orgIds });
  const orgs = rawOrgs?.filter((o): o is Org => !!o);

  //Compute render data
  let teamsToRender = useArrayFilteredBySearch({
    dataArray: p.allTeamsWithSquad,
    primaryTextAccessor: a => a.name,
    searchTerm: filterSearchTerm
  });

  if (filterSearchTerm) {
    //Be super aggressive in filtering out likely irrelevant results so that the select all / deselect all is more useful
    const max = _.maxBy(teamsToRender, a => a.match?.score)?.match?.score || 0;
    teamsToRender = teamsToRender.filter(a => (a.match?.score || 0) >= max * 0.8);
  }

  if (Object.keys(selectedFilterOrgIds).filter(o => selectedFilterOrgIds[o]).length) {
    teamsToRender = teamsToRender.filter(a =>
      a.data.orgId ? selectedFilterOrgIds[a.data.orgId || ""] : selectedFilterOrgIds[STANDALONE_TEAM_KEY]
    );
  }

  if (ObjectKeys(selectedFilterGenders).filter(o => selectedFilterGenders[o]).length) {
    teamsToRender = teamsToRender.filter(a => selectedFilterGenders[a.data.gender]);
  }

  const numSelectedTeams = Object.keys(tempSelectedTeamIdsWithSquad).filter(id => tempSelectedTeamIdsWithSquad[id]).length;

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <StyledText style={{ fontSize: 14, marginRight: 8 }}>{`${numSelectedTeams} teams selected`}</StyledText>
        <FilterIcon style={{ marginRight: 8 }} />
      </Button>
      <Drawer anchor={"right"} open={isOpen} onClose={triggerApplyAndClose} style={{ width: DRAWER_WIDTH }}>
        <View style={{ width: DRAWER_WIDTH }}>
          <List component="nav" aria-labelledby="nested-list-subheader" style={{ padding: 0 }}>
            <ListItem
              button
              onClick={() => {
                setAreFiltersExpanded(!areFiltersExpanded);
              }}
              style={{ paddingTop: 16, paddingBottom: 16 }}
            >
              <ListItemText primary="Filters" />
              {areFiltersExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={areFiltersExpanded} timeout="auto" unmountOnExit className={classes.nested}>
              <ListItem button>
                <ListItemText primary="Organizations" />
              </ListItem>
              {orgs?.map(org => {
                return (
                  <List key={org.id} component="div" disablePadding>
                    <ListItem
                      key={org.id}
                      role={undefined}
                      dense
                      button
                      onClick={() => {
                        setSelectedFilterOrgIds({
                          ...selectedFilterOrgIds,
                          ...{ [org.id]: !selectedFilterOrgIds[org.id] }
                        });
                      }}
                    >
                      <ListItemIcon>
                        <FilterCheckBox color={COLORS.grey_66} selected={!!selectedFilterOrgIds[org.id ?? ""]} />
                      </ListItemIcon>
                      <ListItemText id={org.id} primary={`${org.name}`} />
                    </ListItem>
                  </List>
                );
              })}
              {teamsWithoutOrg.length ? (
                <List component="div" disablePadding>
                  <ListItem
                    key={"standalone"}
                    role={undefined}
                    dense
                    button
                    onClick={() => {
                      setSelectedFilterOrgIds({
                        ...selectedFilterOrgIds,
                        ...{ [STANDALONE_TEAM_KEY]: !selectedFilterOrgIds[STANDALONE_TEAM_KEY] }
                      });
                    }}
                  >
                    <ListItemIcon>
                      <FilterCheckBox color={COLORS.grey_66} selected={!!selectedFilterOrgIds[STANDALONE_TEAM_KEY]} />
                    </ListItemIcon>
                    <ListItemText id={"standalone"} primary={`Standalone Teams`} />
                  </ListItem>
                </List>
              ) : null}
              <ListItem button>
                <ListItemText primary="Gender" />
              </ListItem>

              {_(p.allTeamsWithSquad)
                .map(t => t.gender)
                .uniq()
                .value()
                .map(gender => {
                  const labelId = `gender-heckbox-list-label-${gender}`;
                  return (
                    <List key={gender} component="div" disablePadding>
                      <ListItem
                        key={gender}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          setSelectedFilterGenders({
                            ...selectedFilterGenders,
                            ...{ [gender]: !selectedFilterGenders[gender] }
                          });
                        }}
                      >
                        <ListItemIcon>
                          <FilterCheckBox color={COLORS.grey_66} selected={!!selectedFilterGenders[gender]} />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={gender === GENDERS.boys ? "Boys" : gender === GENDERS.girls ? "Girls" : "Mixed"}
                        />
                      </ListItem>
                    </List>
                  );
                })}
            </Collapse>
          </List>
          <TextField
            InputProps={{
              style: { padding: theme.spacing(2), border: "none" },
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <Search />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setFilterSearchTerm("");
                    if (searchElmRef.current) {
                      searchElmRef.current.value = "";
                      searchElmRef.current.focus();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <SvgIcon fontSize="small" color="action">
                    <Close />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            inputRef={searchElmRef}
            onChange={event => setFilterSearchTerm(event.target.value)}
            className={classes.searchField}
            style={{ borderTop: `1px solid ${theme.palette.divider}`, borderBottom: `1px solid ${theme.palette.divider}` }}
            placeholder={"Search"}
            defaultValue={filterSearchTerm}
          />
          <div style={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <ListItem>
              <div
                tabIndex={0}
                className={classes.selectAllToggleButtons}
                onClick={() => {
                  setTempSelectedTeamIdsWithSquad({
                    ...tempSelectedTeamIdsWithSquad,
                    ...teamsToRender.reduce((acc, data) => {
                      acc[data.data.teamIdWithSquad] = true;
                      return acc;
                    }, {} as Record<string, boolean>)
                  });
                }}
              >
                Select All (+
                {teamsToRender.filter(d => !tempSelectedTeamIdsWithSquad[d.data.teamIdWithSquad]).length})
              </div>
              <span style={{ opacity: 0.7 }}>/</span>
              <div
                tabIndex={0}
                style={{ marginLeft: 16 }}
                className={classes.selectAllToggleButtons}
                onClick={() => {
                  setTempSelectedTeamIdsWithSquad({
                    ...tempSelectedTeamIdsWithSquad,
                    ...teamsToRender.reduce((acc, data) => {
                      acc[data.data.teamIdWithSquad] = false;
                      return acc;
                    }, {} as Record<string, boolean>)
                  });
                }}
              >
                Deselect All (-
                {teamsToRender.filter(d => tempSelectedTeamIdsWithSquad[d.data.teamIdWithSquad]).length})
              </div>
            </ListItem>
          </div>
          <List component="div" disablePadding style={{ paddingBottom: APPLY_BUTTON_HEIGHT }}>
            {teamsToRender.map(t => {
              const onToggle = () => {
                setTempSelectedTeamIdsWithSquad({
                  ...tempSelectedTeamIdsWithSquad,
                  ...{ [t.data.teamIdWithSquad]: !tempSelectedTeamIdsWithSquad[t.data.teamIdWithSquad] }
                });
              };

              return (
                <ListItem style={{ padding: 0, textAlign: "left" }} key={t.data.teamIdWithSquad}>
                  <ButtonBase onClick={onToggle} style={{ width: "100%" }}>
                    <Checkbox onChange={onToggle} checked={!!tempSelectedTeamIdsWithSquad[t.data.teamIdWithSquad]} />
                    <ListItemText style={{ textAlign: "left" }}>
                      {t.match ? (
                        <BoldMatchText matchBitmask={t.match?.textMatches}>{t.data.teamNameWithSquad}</BoldMatchText>
                      ) : (
                        t.data.teamNameWithSquad
                      )}
                    </ListItemText>
                  </ButtonBase>
                </ListItem>
              );
            })}
          </List>
        </View>
        <ShadowView
          style={{
            height: APPLY_BUTTON_HEIGHT,
            width: DRAWER_WIDTH,
            position: "fixed" as any,
            bottom: 0,
            backgroundColor: COLORS.white
          }}
        >
          <TouchableOpacity
            style={{
              margin: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 8,
              borderRadius: 100,
              backgroundColor: numSelectedTeams ? COLORS.blue : COLORS.grey_07
            }}
            disabled={numSelectedTeams < 1}
            onPress={triggerApplyAndClose}
          >
            <StyledText
              style={{
                fontSize: 20,
                padding: 12,
                fontWeight: 500,
                color: numSelectedTeams > 0 ? "white" : undefined
              }}
            >
              Select {numSelectedTeams} Teams
            </StyledText>
          </TouchableOpacity>
        </ShadowView>
      </Drawer>
    </>
  );
}

function FilterCheckBox(p: { selected: boolean; color: COLORS }) {
  return p.selected ? <CheckBoxSelectedIcon style={{ color: p.color }} /> : <CheckBoxUnselectedIcon style={{ color: p.color }} />;
}
