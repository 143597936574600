import React, { CSSProperties } from "react";
import { COLORS } from "@ollie-sports/core";

export interface RadioInputPillboxProps {
  options: { label: string; value: string }[];
  onChange: (option?: string) => void;
  value: string;
  style?: any;
  textStyle?: any;
  unselectedBackgroundColor: COLORS;
  unselectedBorderColor: COLORS;
  unselectedTextColor: COLORS;
  pillHeight: number;
}

export function RadioInputPillbox(p: RadioInputPillboxProps) {
  const optionStyle = Object.assign({}, styles.option, { height: p.pillHeight });
  const unSelectedStyle = {
    backgroundColor: p.unselectedBackgroundColor,
    borderColor: p.unselectedBorderColor
  };
  const selected = Object.assign({}, optionStyle, styles.selected);
  const unselected = Object.assign({}, optionStyle, unSelectedStyle);

  const wrapperStyle = Object.assign({}, styles.wrapper, p.style);
  const textStyle = Object.assign({}, styles.optionText, p.textStyle);
  const unselectedText = Object.assign({}, textStyle, { color: p.unselectedTextColor });

  const renderedOptions = p.options.map((op, idx) => {
    const isSelected = p.value === op.value;
    let opStyle = isSelected ? selected : unselected;
    if (idx === 0) {
      opStyle = Object.assign({}, opStyle, styles.optionLeft);
    }
    if (idx === p.options.length - 1) {
      opStyle = Object.assign({}, opStyle, styles.optionRight);
    }

    return (
      <div key={op.value} onClick={() => p.onChange(op.value)} style={opStyle}>
        <span style={isSelected ? textStyle : unselectedText}>{op.label}</span>
      </div>
    );
  });
  return <div style={wrapperStyle}>{renderedOptions}</div>;
}

const styles: Record<string, CSSProperties> = {
  wrapper: {
    display: "flex"
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    cursor: "pointer"
  },
  optionLeft: {
    borderTopLeftRadius: "2px",
    borderBottomLeftRadius: "2px"
  },
  optionRight: {
    borderTopRightRadius: "2px",
    borderBottomRightRadius: "2px"
  },
  selected: {
    backgroundColor: COLORS.green
  },
  optionText: {
    fontFamily: "Roboto Condensed",
    fontSize: "15px",
    lineHeight: "16px",
    color: COLORS.white
  }
};
