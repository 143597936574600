import { Simplify } from "type-fest";
import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  CheckboxProps,
  Checkbox,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  RadioGroupProps,
  RadioGroup
} from "@material-ui/core";

import { translate } from "@ollie-sports/i18n";
import { StyledText } from "./StyledText";
import { DatePicker, DatePickerProps, KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { useDidUpdateEffect } from "../hooks/useDidUpdateEffect";
import { TouchableOpacity, View, ViewStyle } from "react-native-web";
import { RadioInputPillbox, RadioInputPillboxProps } from "./RadioInputPillbox";
import { CoolTextInput, CoolTextInputProps, CoolTextInputWithLabel, CoolTextInputWithLabelProps } from "./Inputs/CoolTextInput";
import { CoolDateInput, CoolDateInputProps } from "./Inputs/CoolDateInput";
import { CoolSelectInput, CoolSelectInputProps } from "./Inputs/CoolSelectInput";
import {
  CoolMultiSelectInput,
  CoolMultiSelectInputProps,
  CoolMultiSelectInputWithLabelProps,
  CoolMultiSelectWithLabel
} from "./Inputs/CoolMultiSelectInput";
import { CoolTextArea, CoolTextAreaProps, CoolTextAreaWithLabel, CoolTextAreaWithLabelProps } from "./Inputs/CoolTextArea";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { SerializedFile } from "@ollie-sports/models";
import { InfoTooltipIcon } from "./InfoTooltip";

type SetterProps = {
  componentIsMounted: boolean;
  componentId: string;
  runValidation?: (opts?: { shouldRunSilently: boolean }) => boolean;
};

type CheckboxGroupProps = {
  value?: string[];
  options: { value: string; label: string }[];
  onChange: (currVal: undefined | string[]) => void;
  style?: ViewStyle;
};

export const PrettyRadioGroup = makeFormField<RadioGroupProps>(RadioGroup, { shouldSetError: false });
export const PrettyCheckbox = makeFormField<Omit<CheckboxProps, "value"> & { label?: ReactNode; infoTooltip?: string }>(
  LabeledCheckbox,
  {
    getValue: a => a.checked,
    shouldSetError: false
  }
);
export const PrettyCheckboxGroup = makeFormField<CheckboxGroupProps>(CheckboxGroup, { shouldSetError: false });
export const PrettyInput = makeFormField<TextFieldProps, string>(TextField);
export const PrettyFileInput = makeFormField<FileInputFieldProps, SerializedFile | null>(FileInputField);
export const PrettyKeyboardDatePicker = makeFormField<KeyboardDatePickerProps>(KeyboardDatePicker);
export const PrettyDatePicker = makeFormField<DatePickerProps>(DatePicker);
export const PrettySelect = makeFormField<SelectProps>(Select);
export const PrettyRadioInputPillbox = makeFormField<RadioInputPillboxProps>(RadioInputPillbox);
export const PrettyCoolTextInputWithLabel = makeFormField<CoolTextInputWithLabelProps>(CoolTextInputWithLabel);
export const PrettyCoolTextInput = makeFormField<CoolTextInputProps>(CoolTextInput);
export const PrettyCoolTextArea = makeFormField<CoolTextAreaProps>(CoolTextArea);
export const PrettyCoolTextAreaWithLabel = makeFormField<CoolTextAreaWithLabelProps>(CoolTextAreaWithLabel);
export const PrettyCoolMultiSelect = makeFormField<CoolMultiSelectInputProps, { label: string; value: any }[]>(
  CoolMultiSelectInput
);
export const PrettyCoolMultiSelectWithLabel = makeFormField<CoolMultiSelectInputWithLabelProps>(CoolMultiSelectWithLabel);
export const PrettyCoolDateInput = makeFormField<CoolDateInputProps, Date>(CoolDateInput, { changeCanTriggerErrorUI: true });
export const PrettyCoolSelectInput = makeFormField<CoolSelectInputProps>(CoolSelectInput);

type FileInputFieldProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "value" | "onChange" | "placeholder"
> & {
  value: SerializedFile[] | null;
  label: string;
  accept?: string;
  subtitle?: string;
  multiple?: boolean;
  error?: any;
  onChange?: (a: SerializedFile[] | null) => void;
};

function FileInputField(p: FileInputFieldProps) {
  const { onChange, label, value, type, className, style, error, accept, ...rest } = p;
  const inputRef = useRef<any>(null);

  return (
    <div style={style} className={className}>
      <label className={twMerge("block font-bold text-sm", !p.subtitle && "mb-2")}>{label}</label>
      {p.subtitle ? <div className="text-xs font-medium mb-2">{` ${p.subtitle}`}</div> : null}
      <div className="flex gap-2">
        <button
          onClick={() => {
            inputRef.current?.click();
          }}
          className="bg-blue-400 rounded-md hover:bg-blue-600 text-white font-bold py-2 px-4 inline-flex items-center"
        >
          <svg fill="#FFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
          </svg>
          <span className="ml-2">Select Files</span>
        </button>

        <div className="flex flex-1 items-center gap-2">
          {(p.value?.map(a => (a.name.length > 20 ? "..." + a.name.slice(-20) : a.name)) || ["No File Selected"]).map(
            (a, index) => (
              <span className="text-xs" key={index}>
                {a}
              </span>
            )
          )}
        </div>
      </div>
      <input
        {...rest}
        ref={inputRef}
        className="hidden"
        type="file"
        accept={accept}
        onChange={async e => {
          if (e.target.files) {
            onChange?.(
              await Promise.all(
                Array.from(e.target.files).map(async (a: File) => {
                  const bytes = new Uint8Array(await a.arrayBuffer());
                  const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), "");
                  const base64 = window.btoa(binary);

                  return {
                    name: a.name,
                    type: a.type,
                    size: a.size,
                    base64Content: base64
                  };
                })
              )
            );
          } else {
            onChange?.(null);
          }
        }}
      />
    </div>
  );
}

function CheckboxGroup(p: CheckboxGroupProps) {
  return (
    <View style={p.style}>
      {p.options.map((o, i) => (
        <View key={o.value} style={{ flexDirection: "row", alignItems: "center" }}>
          <Checkbox
            onChange={() => {
              if (!p.value) {
                p.onChange([o.value]);
              } else {
                const selectionIndex = p.value.findIndex(a => a === o.value);
                if (selectionIndex === -1) {
                  p.onChange([...p.value, o.value]);
                } else {
                  const newValue = p.value.slice();
                  newValue.splice(selectionIndex, 1);
                  p.onChange(newValue);
                }
              }
            }}
            checked={!!p.value?.find(a => a === o.value)}
          />
          <label>
            <StyledText>{o.label}</StyledText>
          </label>
        </View>
      ))}
    </View>
  );
}

function LabeledCheckbox(p: CheckboxProps & { label: ReactNode; infoTooltip?: string }) {
  const { label, id: idRaw, className, ...rest } = p;

  const randomId = useRef(Math.random() + "" + Math.random()).current;

  const id = idRaw || randomId;

  return (
    <div
      className={clsx("flex items-center cursor-pointer select-none", className)}
      onClick={e => {
        const checkboxElm = document.getElementById(id)!;
        if (!checkboxElm.contains(e.target as HTMLElement)) {
          checkboxElm.click();
        }
      }}
    >
      <Checkbox id={id} {...rest} />
      {label}

      {p.infoTooltip ? <InfoTooltipIcon title={p.infoTooltip} /> : null}
    </div>
  );
}

export type FormRef = { validate: (opts?: { shouldRunSilently: boolean }) => boolean };

export const Form = React.forwardRef<FormRef, { children: ReactNode | ((isValid: boolean) => ReactNode) }>((p, ref) => {
  const childValidators = useRef<Record<string, (opts?: { shouldRunSilently: boolean }) => boolean>>({});

  const [isValid, setIsValid] = useState(true);
  const [formHasValidated, setHasFormValidated] = useState(false);

  const validate = useCallback((opts?: { shouldRunSilently: boolean }) => {
    let isValid = true;
    Object.values(childValidators.current).forEach(validator => {
      if (!validator(opts)) {
        isValid = false;
      }
    });

    if (!opts?.shouldRunSilently) {
      setHasFormValidated(true);
    }
    return isValid;
  }, []);

  const onChildFormFieldChange = useCallback(() => {
    setIsValid(validate({ shouldRunSilently: true }));
  }, []);

  const formChildSetter = useCallback((s: SetterProps) => {
    let didUpdate = false;

    if (s.runValidation && !childValidators.current[s.componentId]) {
      didUpdate = true;
    } else if (!s.componentIsMounted && childValidators.current[s.componentId]) {
      delete childValidators.current[s.componentId];
      didUpdate = true;
    }

    if (s.componentIsMounted && s.runValidation) {
      childValidators.current[s.componentId] = s.runValidation;
    }

    if (didUpdate) {
      setIsValid(validate({ shouldRunSilently: true }));
    }
  }, []);

  useImperativeHandle(ref, () => ({
    validate
  }));

  const componentId = useRef(Math.random() + "" + Math.random()).current;
  const registerFormChild = useContext(RegisterFormChildContext);
  const notifyParentFormOfChange = useContext(NotifyParentFormOfChange);

  useDidUpdateEffect(() => {
    notifyParentFormOfChange();
  }, [isValid]);

  useEffect(() => {
    registerFormChild({ componentId, componentIsMounted: true, runValidation: validate });
    return () => {
      registerFormChild({ componentId, componentIsMounted: false, runValidation: validate });
    };
  });

  return (
    <NotifyParentFormOfChange.Provider value={onChildFormFieldChange}>
      <RegisterFormChildContext.Provider value={formChildSetter}>
        <FormHasValidatedContext.Provider value={formHasValidated}>
          {typeof p.children === "function" ? p.children(isValid) : p.children}
        </FormHasValidatedContext.Provider>
      </RegisterFormChildContext.Provider>
    </NotifyParentFormOfChange.Provider>
  );
});

Form.displayName = "CoolForm";

function makeFormField<Props extends { onBlur?: any; onChange?: any; value?: any }, ValueType = string>(
  Component: any,
  opts?: { getValue?: (a: any) => any; shouldSetError?: boolean; changeCanTriggerErrorUI?: boolean }
) {
  return function FormField(
    p: Simplify<
      Omit<Props, "error"> & {
        validate?: (currVal?: ValueType) => string;
        blurShouldTriggerValidation?: boolean; //Default false
        isRequired?: boolean | string;
        errorStyles?: CSSProperties;
      }
    >
  ) {
    const [isTouchedOverride, setIsTouchedOverride] = useState(false);
    const { onBlur, onChange, validate, isRequired, ...rest } = p;

    const currValidate = useRef(p.validate);
    currValidate.current = p.validate;

    const errorMsg = useFormAwareErrorMsg({
      validationFn: (v, info) => {
        if (!info.isRunningSilently) {
          setIsTouchedOverride(true);
        }
        if (p.isRequired && (!v || (Array.isArray(v) && !v.length))) {
          return typeof p.isRequired === "string" ? p.isRequired : translate.common.IsRequired;
        }
        return p.validate?.(v) ?? "";
      },
      currentVal: opts?.getValue ? opts.getValue(p) : p.value,
      isTouchedOverride: isTouchedOverride
    });

    return (
      <>
        <Component
          error={opts?.shouldSetError === false ? undefined : !!errorMsg ? true : undefined}
          onChange={(a: any) => {
            if (opts?.changeCanTriggerErrorUI) {
              setIsTouchedOverride(true);
            }
            onChange?.(a);
          }}
          onBlur={(e: any) => {
            if (p.blurShouldTriggerValidation !== false) {
              setIsTouchedOverride(true);
            }
            onBlur?.(e);
          }}
          {...rest}
        />
        {errorMsg ? (
          <StyledText style={{ color: "#f44336", paddingTop: 4, fontSize: 12, ...(p.errorStyles || {}) }}>{errorMsg}</StyledText>
        ) : null}
      </>
    );
  };
}

const RegisterFormChildContext = React.createContext<(t: SetterProps) => void>(() => {});
const FormHasValidatedContext = React.createContext<boolean>(false);
const NotifyParentFormOfChange = React.createContext<() => void>(() => {});

/**
 * Hooks up the input component value to a validation function. When the value changes AND the component is touched (defaults to true),
 * returns an error message which the input component should render.
 *
 * This hook also hooks up the input validation state to a parent Form component, so that the parent Form
 * can detect the validation state of all its nested inputs.
 *
 */
let compCount = 0;
function useFormAwareErrorMsg<T>(inputs: {
  validationFn?: (currVal: T, info: { isRunningSilently: boolean }) => string;
  currentVal: T;
  isTouchedOverride?: boolean;
}) {
  const registerFormChild = useContext(RegisterFormChildContext);
  const parentFormHasRunValidation = useContext(FormHasValidatedContext);
  const notifyParentFormOfChange = useContext(NotifyParentFormOfChange);
  const [errorMsg, setErrorMsg] = useState("");
  const [componentId] = useState(() => (++compCount).toString());

  const currentVal = useRef(inputs.currentVal);
  useEffect(() => {
    currentVal.current = inputs.currentVal;
  }, [inputs.currentVal]);

  function validator(opts?: { shouldRunSilently: boolean }) {
    if (inputs.validationFn) {
      const msg = inputs.validationFn(currentVal.current, { isRunningSilently: opts?.shouldRunSilently === true });
      if (errorMsg !== msg && !opts?.shouldRunSilently) {
        setErrorMsg(msg);
      }
      return !msg;
    }
    return true;
  }

  const initialVal = useRef(inputs.currentVal);
  const isTouched = useRef(parentFormHasRunValidation);

  //Detect if the input has ever been touched.
  useEffect(() => {
    if (inputs.currentVal !== initialVal.current) {
      isTouched.current = true;
    }
    if (parentFormHasRunValidation) {
      isTouched.current = true;
    }
  });

  //Register the new validation function every render with the parent
  useEffect(() => {
    registerFormChild({ componentId, componentIsMounted: true, runValidation: validator });
    return () => {
      registerFormChild({ componentId, componentIsMounted: false, runValidation: validator });
    };
  });

  //Run validation every render if the component has been touched
  useEffect(() => {
    if (inputs.isTouchedOverride === false) {
      return;
    }

    if (inputs.isTouchedOverride || isTouched.current) {
      validator();
    }
  });

  useDidUpdateEffect(() => {
    notifyParentFormOfChange();
  }, [inputs.currentVal]);

  return errorMsg;
}
