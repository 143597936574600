import {
  OrgRegistrationQuestion__Checkbox,
  OrgRegistrationQuestion__Explainer,
  OrgRegistrationQuestion__FreeResponse,
  OrgRegistrationQuestion__LegalDoc,
  OrgRegistrationQuestion__Radio
} from "@ollie-sports/models";
import { View } from "react-native-web";
import { StyledText } from "./StyledText";
import { escapeHtmlAndWrapLinks } from "../utils/parseText";
import { PrettyCheckbox, PrettyCoolMultiSelect, PrettyCoolSelectInput, PrettyCoolTextArea } from "./Form";
import { ReactNode } from "react";
import { translate } from "@ollie-sports/i18n";
import { COLORS } from "@ollie-sports/core";

interface BaseProps {
  hideTopBorder?: boolean;
}

export function OrgRegistrationQuestionExplainer(p: BaseProps & { question: OrgRegistrationQuestion__Explainer }) {
  return (
    <OrgRegistrationQuestionContainer {...p}>
      <OrgQuestionLabel label={p.question.shortTitle} isRequired={false} />
      {p.question.textMD.split(/\n+/).map((paragraph, index) => {
        return (
          <p
            key={index}
            style={{ marginTop: index === 0 ? 0 : 8 }}
            dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(paragraph) }}
          />
        );
      })}
    </OrgRegistrationQuestionContainer>
  );
}

export function OrgRegistrationQuestionFreeResponse(
  p: BaseProps & { question: OrgRegistrationQuestion__FreeResponse; value: string; onChangeValue: (newVal: string) => void }
) {
  return (
    <OrgRegistrationQuestionContainer {...p}>
      <OrgQuestionLabel label={p.question.shortTitle} isRequired={p.question.isRequired} />
      <View style={{ marginTop: 4, marginBottom: 14 }}>
        <StyledText>{p.question.questionMD}</StyledText>
      </View>

      <PrettyCoolTextArea
        isRequired={p.question.isRequired}
        placeholder={translate({ defaultMessage: "Enter response..." })}
        onChange={newVal => {
          p.onChangeValue(newVal ?? "");
        }}
        value={p.value}
      />
    </OrgRegistrationQuestionContainer>
  );
}

export function OrgRegistrationQuestionRadio(
  p: BaseProps & { question: OrgRegistrationQuestion__Radio; value: string; onChangeValue: (newVal: string) => void }
) {
  return (
    <OrgRegistrationQuestionContainer {...p}>
      <OrgQuestionLabel label={p.question.shortTitle} isRequired={p.question.isRequired} />
      <PrettyCoolSelectInput
        onChange={newVal => {
          p.onChangeValue(newVal);
        }}
        allowClear={!p.question.isRequired}
        isRequired={p.question.isRequired}
        value={p.value}
        options={p.question.options.map(o => {
          return {
            label: o.valueMD,
            value: o.valueMD
          };
        })}
      />
    </OrgRegistrationQuestionContainer>
  );
}

export function OrgRegistrationQuestionCheckbox(
  p: BaseProps & { question: OrgRegistrationQuestion__Checkbox; value: string[]; onChangeValue: (newVal: string[]) => void }
) {
  return (
    <OrgRegistrationQuestionContainer {...p}>
      <OrgQuestionLabel label={p.question.shortTitle} isRequired={p.question.isRequired} />
      <PrettyCoolMultiSelect
        onChange={newVal => {
          p.onChangeValue(newVal.map(v => v.value));
        }}
        isRequired={p.question.isRequired}
        value={p.value.map(v => {
          return {
            label: v,
            value: v
          };
        })}
        options={p.question.options.map(o => {
          return {
            label: o.valueMD,
            value: o.valueMD
          };
        })}
      />
    </OrgRegistrationQuestionContainer>
  );
}

export function OrgRegistrationQuestionLegalDoc(
  p: BaseProps & { question: OrgRegistrationQuestion__LegalDoc; value: boolean; onChangeValue: (newVal: boolean) => void }
) {
  return (
    <OrgRegistrationQuestionContainer {...p}>
      <OrgQuestionLabel label={p.question.shortTitle} isRequired={p.question.isRequired} />
      {p.question.textMD.split(/\n+/).map((paragraph, index) => {
        return (
          <p
            key={index}
            style={{ marginTop: index === 0 ? 0 : 8 }}
            dangerouslySetInnerHTML={{ __html: escapeHtmlAndWrapLinks(paragraph) }}
          />
        );
      })}
      <PrettyCheckbox
        isRequired={p.question.isRequired}
        label={
          <label>
            <StyledText style={{ textAlign: "left", textDecoration: "underline" }}>
              {translate({ defaultMessage: "I Agree" })}
            </StyledText>
          </label>
        }
        onChange={e => {
          p.onChangeValue(e.target.checked);
        }}
        checked={p.value}
      />
    </OrgRegistrationQuestionContainer>
  );
}

function OrgRegistrationQuestionContainer(p: BaseProps & { children: ReactNode }) {
  return <View style={{ marginTop: p.hideTopBorder ? 0 : 16 }}>{p.children}</View>;
}

function OrgQuestionLabel(p: { label: string; isRequired: boolean }) {
  const optionalElm = !p.isRequired ? (
    <StyledText style={{ color: COLORS.grey_66 }}>{` (${translate.common.Optional})`}</StyledText>
  ) : null;
  return (
    <StyledText style={{ fontWeight: "bold", marginBottom: 4 }}>
      {p.label}
      {optionalElm}
    </StyledText>
  );
}
