import clsx from "clsx";
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";
import { useElmRefListener } from "../hooks/useElmRefListener";

type Props = { onDrop: (files: FileList) => void; children: ReactNode; dragClassName?: string } & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onDrop"
>;

export function DropZone(p: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [isValidHover, setIsValidHover] = useState(false);

  const latestOnDropRef = useRef(p.onDrop);
  latestOnDropRef.current = p.onDrop;

  useEffect(() => {
    return lazilyPreventDefaultPageDropBehavior();
  }, []);

  //Prevent DragOver Default effect
  useElmRefListener(
    ref,
    "dragover",
    e => {
      e.stopPropagation();
      e.preventDefault();
    },
    []
  );

  //Handle drops
  useElmRefListener(
    ref,
    "drop",
    e => {
      e.preventDefault();
      if (e.dataTransfer?.files) {
        latestOnDropRef.current(e.dataTransfer?.files);
      }
    },
    []
  );

  useElmRefListener(
    ref,
    "dragenter",
    e => {
      if (!ref.current?.contains(e.relatedTarget as any)) {
        setIsValidHover(true);
      }
    },
    []
  );

  useElmRefListener(
    ref,
    "dragleave",
    e => {
      if (!ref.current?.contains(e.relatedTarget as any)) {
        setIsValidHover(false);
      }
    },
    []
  );

  const { className, onDrop, dragClassName, ...rest } = p;

  return <div className={clsx(isValidHover && dragClassName, className)} ref={ref} {...rest} />;
}

export function SingleImageDropZone(p: { onDrop: (file: File) => void } & Omit<Props, "onDrop">) {
  const { onDrop, ...rest } = p;
  return (
    <DropZone
      {...rest}
      onDrop={files => {
        // check if there's exactly one file and it's an image
        if (files.length === 1 && files[0].type.startsWith("image/")) {
          onDrop(files[0]);
        }
      }}
    />
  );
}

//Prevent the lame default page drop behavior of opening the image in a new tab.
let isSet = false;
function lazilyPreventDefaultPageDropBehavior() {
  if (!isSet) {
    const prevDefault = (e: any) => e.preventDefault();

    window.addEventListener("dragover", prevDefault, false);
    window.addEventListener("drop", prevDefault, false);

    return () => {
      window.removeEventListener("dragover", prevDefault, false);
      window.removeEventListener("drop", prevDefault, false);
    };
  } else {
    return () => {};
  }
}
