import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Card,
  TextField,
  Divider,
  SvgIcon,
  InputAdornment,
  TablePagination,
  CircularProgress,
  Checkbox,
  useTheme,
  ButtonBase
} from "@material-ui/core";
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { Theme } from "../theme";
import { BoldMatchText } from "./BoldMatchText";
import _, { flatten } from "lodash";
import Parallel from "paralleljs";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import { Search } from "react-feather";
import { getFuzzyMatches, getFuzzyMatchesAsync } from "../utils/fuzzyTextSearchFaux";
import { View } from "react-native-web";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { useDebouncedFn } from "../utils/hooks/useDebouncedFn";
import { Div } from "./DOM";
import { ShadowView } from "./ShadowView";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  queryField: {
    width: 500
  },
  row: {
    position: "relative",
    display: "flex"
  },
  rowHover: {
    "&:hover": {
      backgroundColor: "rgb(245, 245, 245)"
    }
  },
  cell: {
    overflow: "hidden",
    padding: theme.spacing(2),
    verticalAlign: "top",
    borderBottom: "none"
  },
  pureTextCell: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  expandoArrow: {},
  headerCell: {
    fontWeight: "bold",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

const CHECKBOX_CELL_WIDTH = 44;

export interface CoolCell {
  text: string | string[];
  disableSearch?: boolean;
  textSearchScaleFactor?: number;
  getCellComponent?: (text: ReactNode[]) => ReactNode;
  getCellStyle?: () => any;
}

export interface CoolRow {
  id: string;
  isSelected?: boolean; //If enableRowSelection is true but isSelected is undefined for a row, that is interpreted as false.
  disableSearch?: boolean;
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  cells: (CoolCell | string)[];
  rowStyle?: CSSProperties;
  subRows?: CoolRow[];
}

export function CoolTable(p: {
  rows: CoolRow[];
  columnPercentWidths: ({ fixedWidth: number } | number)[]; //Defaults to percent width
  enableSearch?: boolean;
  searchOpts?: {
    placeholder?: string;
  };
  enableRowSelection?: boolean;
  onRowSelection?: (a: { changedRowIds: string[]; newValue: boolean }) => void;
  headerCells?: (string | ReactNode | ((baseProps: { className: string; style: React.CSSProperties }) => ReactNode))[];
  paginationOpts?: {
    label: string;
    rowsPerPageOptions?: number[];
  };
}) {
  const classes = useStyles();
  const [searchTerm, setSearchTermRaw] = useState<string>("");
  const setSearchTerm = useDebouncedFn({
    fn: setSearchTermRaw,
    deps: [],
    ms: 50
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const rowsPerPageOptions = p.paginationOpts?.rowsPerPageOptions ?? ROWS_PER_PAGE;
  const [numberRowsPerPage, setNumberRowsPerPage] = useState(rowsPerPageOptions[0]);

  const theme = useTheme();

  const [state, setState] = useState<
    | {
        hasLoaded: true;
        rowMetaByRowId: Record<string, RowMeta>;
        flatRowsFilteredBySearchTerm: FlatRow[];
        subRowExpansionMap: { [parentRowId in string]?: boolean };
      }
    | { hasLoaded: false }
  >({ hasLoaded: false });

  async function computeNextState(a: { rows: CoolRow[]; searchTerm: string }) {
    const nextRowMetaByRowId = await computeRowMetaByRowId({ rows: a.rows, searchTerm: a.searchTerm });
    const nextFlatRowsFilteredBySearchTerm = computeFlatRowsFilteredBySearchTerm({
      rows: a.rows,
      searchTerm: a.searchTerm,
      rowMetaByRowId: nextRowMetaByRowId
    });
    const nextExpansionMap = getNewSearchTermSubRowExpansionMap({
      searchTerm: a.searchTerm,
      rowMetaByRowId: nextRowMetaByRowId,
      flatRowsFilteredBySearchTerm: nextFlatRowsFilteredBySearchTerm
    });

    isMounted.current &&
      setState({
        hasLoaded: true,
        flatRowsFilteredBySearchTerm: nextFlatRowsFilteredBySearchTerm,
        rowMetaByRowId: nextRowMetaByRowId,
        subRowExpansionMap: nextExpansionMap
      });
  }

  const isMounted = useIsMountedRef();
  const computeNextStateDebounced = useDebouncedFn({
    fn: computeNextState,
    ms: 200,
    deps: []
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      const percentSum = p.columnPercentWidths.reduce((a, b) => (typeof b === "number" ? b + (a as any) : a), 0) as number;
      if (Math.round(percentSum) !== 100) {
        console.error("Property columnWidth does not have percent widths summing to 100");
      }
    }

    if (state.hasLoaded) {
      computeNextStateDebounced({ rows: p.rows, searchTerm });
    } else {
      computeNextState({ rows: p.rows, searchTerm });
    }
  }, [searchTerm, p.rows]);

  if (!state.hasLoaded) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </View>
    );
  }
  const { rowMetaByRowId, subRowExpansionMap, flatRowsFilteredBySearchTerm } = state;

  //Filter out based on search term
  let flatRowsToRender = flatRowsFilteredBySearchTerm.slice();

  //Filter out paginated rows
  if (p.paginationOpts && flatRowsToRender.length) {
    const topLevelRowStartIndex = selectedPageIndex * numberRowsPerPage;
    const topLevelRowEndIndex = (selectedPageIndex + 1) * numberRowsPerPage;
    let sliceStartIndex: number | undefined;
    let sliceEndIndex: number | undefined;

    let currentTopLevelRowIndex = -1;
    for (let i = 0; i < flatRowsToRender.length; i++) {
      const row = flatRowsToRender[i];
      if (row.depth === 0) {
        currentTopLevelRowIndex++;
        if (currentTopLevelRowIndex === topLevelRowStartIndex) {
          sliceStartIndex = i;
        }
        if (currentTopLevelRowIndex === topLevelRowEndIndex) {
          sliceEndIndex = i;
          break;
        }
      }
    }
    if (sliceEndIndex === undefined) {
      sliceEndIndex = flatRowsToRender.length;
    }
    if (sliceStartIndex !== undefined && sliceEndIndex !== undefined) {
      flatRowsToRender = flatRowsToRender.slice(sliceStartIndex, sliceEndIndex);
    }
  }

  //Filter out collapsed rows
  flatRowsToRender = flatRowsToRender.filter(r => {
    const { parentRow } = rowMetaByRowId[r.row.id];
    return parentRow ? subRowExpansionMap[parentRow.id] : true;
  });

  return (
    <ShadowView style={{ position: "relative" }}>
      {p.enableSearch || p.paginationOpts ? (
        <View>
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            {p.enableSearch ? (
              <TextField
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setSearchTerm(event.target.value)}
                placeholder={p.searchOpts?.placeholder ?? "Search"}
                defaultValue=""
                variant="outlined"
              />
            ) : null}
            {p.paginationOpts ? (
              <TablePagination
                component="div"
                labelRowsPerPage={p.paginationOpts.label}
                style={{ marginLeft: "auto" }}
                count={flatRowsFilteredBySearchTerm.filter(r => r.depth === 0).length}
                onChangePage={(__, newPage) => setSelectedPageIndex(newPage)}
                onChangeRowsPerPage={e => setNumberRowsPerPage(parseInt(e.target.value))}
                page={selectedPageIndex}
                rowsPerPage={numberRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={() => {}}
              />
            ) : null}
          </Box>
          <Divider />
        </View>
      ) : null}

      <Box minWidth={600} maxWidth="100%">
        <div style={{ width: "100%", wordWrap: "break-word" }}>
          {p.headerCells ? (
            <div style={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
              <div style={{ display: "flex" }}>
                {p.enableRowSelection ? (
                  <div className={clsx(classes.cell, classes.headerCell)} style={{ width: CHECKBOX_CELL_WIDTH, padding: 0 }}>
                    <Checkbox
                      onChange={() => {
                        const isAllSelected = flatRowsFilteredBySearchTerm.every(r => r.row.isSelected);
                        const newValue = isAllSelected ? false : true;
                        const changedRowIds = flatRowsFilteredBySearchTerm
                          .filter(r => !!r.row.isSelected === newValue)
                          .map(r => r.row.id);
                        p.onRowSelection?.({ changedRowIds, newValue });
                      }}
                    />
                  </div>
                ) : null}
                {p.headerCells.map((cell, i) => (
                  <React.Fragment key={i}>
                    {(() => {
                      const width = getColumnWidth({
                        cellIndex: i,
                        columnPercentWidths: p.columnPercentWidths,
                        enableRowSelection: p.enableRowSelection
                      });

                      const elm =
                        typeof cell === "function" ? (
                          cell({ style: { width }, className: clsx(classes.cell, classes.headerCell) })
                        ) : (
                          <div className={clsx(classes.cell, classes.headerCell)} style={{ width }}>
                            {typeof cell === "string" ? <span>{cell}</span> : cell}
                          </div>
                        );

                      return elm;
                    })()}
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
          <div>
            {flatRowsToRender
              .map(r => r.row)
              .map(row => (
                <CoolRowComponent
                  key={row.id}
                  row={row}
                  columnPercentWidths={p.columnPercentWidths}
                  rowMeta={rowMetaByRowId[row.id]}
                  selectionOpts={p.enableRowSelection ? { onSelectToggle: () => {} } : undefined}
                  subRowsExpandoIcon={
                    row.subRows
                      ? {
                          isExpanded: !!subRowExpansionMap[row.id],
                          onToggle: newVal => {
                            const rowsToToggle = [row.id];
                            if (row.subRows && !newVal) {
                              traverseAllRows(row.subRows, subRow => {
                                if (subRowExpansionMap[subRow.row.id]) {
                                  rowsToToggle.push(subRow.row.id);
                                }
                              });
                            }
                            setState(a => {
                              if (!a.hasLoaded) {
                                return a;
                              }

                              const newExpansion = { ...a.subRowExpansionMap };
                              rowsToToggle.forEach(r => {
                                newExpansion[r] = newVal;
                              });
                              return {
                                ...a,
                                subRowExpansionMap: newExpansion
                              };
                            });
                          }
                        }
                      : undefined
                  }
                />
              ))}
          </div>
        </div>
      </Box>
    </ShadowView>
  );
}

function CoolRowComponent(p: {
  row: CoolRow;
  rowMeta: RowMeta;
  columnPercentWidths: ({ fixedWidth: number } | number)[];
  subRowsExpandoIcon?: { onToggle: (newState: boolean) => void; isExpanded: boolean };
  selectionOpts?: {
    onSelectToggle: () => void;
    checkboxStyle?: React.CSSProperties;
  };
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const extraProps: any = {
    onClick: p.selectionOpts?.onSelectToggle ?? p.row.onClick,
    to: p.row.to,
    component: p.row.to ? Link : "div"
  };

  return (
    <Box
      onClick={p.row.onClick}
      className={clsx(classes.row, p.selectionOpts && classes.rowHover)}
      {...extraProps}
      style={{
        ...(p.row.rowStyle ? p.row.rowStyle : {}),
        ...{
          boxShadow: `0px 1px 0px 0px ${theme.palette.divider}`,
          cursor: p.selectionOpts ? "pointer" : undefined
        }
      }}
    >
      {p.selectionOpts ? (
        <div
          className={clsx(classes.cell, classes.headerCell)}
          style={{ ...{ width: CHECKBOX_CELL_WIDTH, padding: 0 }, ...(p.selectionOpts.checkboxStyle || {}) }}
        >
          <Checkbox value={!!p.row.isSelected} onChange={p.selectionOpts.onSelectToggle} style={{ padding: 0 }} />
        </div>
      ) : null}
      {p.rowMeta.highlightedCells.map((cell, i) => {
        const { highlightedText, getCellComponent, getCellStyle } = cell;
        const inner = getCellComponent?.(highlightedText) ?? highlightedText;

        let insetElements: ReactNode = null;
        if (i === 0) {
          const expandoWidth = 42;
          let expandoIcon: JSX.Element | null = null;

          if (p.subRowsExpandoIcon) {
            const { isExpanded, onToggle } = p.subRowsExpandoIcon;
            const ExpandoComponent = isExpanded ? KeyboardArrowDown : KeyboardArrowRight;

            expandoIcon = ExpandoComponent ? (
              <div style={{ width: expandoWidth, overflow: "visible", display: "inline-block", position: "relative" }}>
                <IconButton
                  onClick={() => onToggle(!isExpanded)}
                  style={{ position: "absolute", top: -23, height: 36, width: 36, color: "black" }}
                >
                  <ExpandoComponent />
                </IconButton>
              </div>
            ) : null;
          }

          const nestingInsetSpacer = p.rowMeta.nestingDepth ? (
            <span style={{ paddingLeft: p.rowMeta.nestingDepth * expandoWidth }} />
          ) : null;

          insetElements = (
            <>
              {nestingInsetSpacer}
              {expandoIcon}
            </>
          );
        }

        const style = {
          ...(getCellStyle?.() || {}),
          ...(p.selectionOpts && p.subRowsExpandoIcon ? { paddingLeft: 0 } : {}),
          ...{
            width: getColumnWidth({
              cellIndex: i,
              columnPercentWidths: p.columnPercentWidths,
              enableRowSelection: !!p.selectionOpts
            })
          }
        };
        return (
          <div key={i} className={clsx(classes.cell, !getCellComponent && classes.pureTextCell)} style={style}>
            {insetElements}
            {inner}
          </div>
        );
      })}
    </Box>
  );
}

type HighlightedCell = CoolCell & { highlightedText: ReactNode[] };

type RowMeta = {
  highlightedCells: HighlightedCell[]; //An array of highlighted ReactNode's for every cell in the row (corresponding to the array of strings for each cell)
  nestingDepth: number; //How deep the row is in the row tree
  parentRow?: CoolRow;
  //Below properties are ignored if there's no search term
  totalTextMatchScore: number; //Sum of the row's score + parents own text match scores and children sub rows scores
  ownTextMatchScore: number;
};

type RowMetaByRowId = Record<string, RowMeta>;

async function computeRowMetaByRowId(p: { rows: CoolRow[]; searchTerm: string }): Promise<RowMetaByRowId> {
  let rowMetaByRowId: Record<string, RowMeta> = {};

  const searchTerms = p.searchTerm
    ? p.searchTerm
        .trim()
        .split(" ")
        .map(t => t.trim())
    : [];

  //Initialize meta
  traverseAllRows(p.rows, ({ row, depth, parentRow }) => {
    rowMetaByRowId[row.id] = {
      highlightedCells: row.cells.map(cell => {
        const baseCell = typeof cell === "string" ? { text: [cell] } : cell;
        const highlightedText = baseCell.text instanceof Array ? baseCell.text : [baseCell.text];
        return {
          ...baseCell,
          highlightedText
        };
      }),
      parentRow,
      nestingDepth: depth,
      totalTextMatchScore: 0,
      ownTextMatchScore: 0
    };
  });

  //If search term
  if (searchTerms.length) {
    type SearchContext = {
      rowId: string;
      cell: HighlightedCell;
      cellIndex: number;
      cellStringArrayIndex: number;
      text: string;
    };

    //Assemble array of text to search
    const arrayToSearchOver: SearchContext[] = [];
    traverseAllRows(p.rows, ({ row }) => {
      if (!row.disableSearch) {
        rowMetaByRowId[row.id].highlightedCells.forEach((cell, cellIndex) => {
          if (!cell.disableSearch) {
            const cellStringArray = cell.text instanceof Array ? cell.text : [cell.text];

            cellStringArray.forEach((text, cellStringArrayIndex) => {
              arrayToSearchOver.push({
                rowId: row.id,
                cell,
                cellIndex,
                cellStringArrayIndex,
                text
              });
            });
          }
        });
      }
    });

    let matches = (
      await getFuzzyMatchesAsync<SearchContext>({
        searchTerm: p.searchTerm,
        arrayToSearch: arrayToSearchOver.map(r => ({
          primaryText: r.text,
          context: r
        }))
      })
    ).filter(a => a.match.score > 0);

    const grouped = _(matches)
      .groupBy(a => a.context.rowId)
      .mapValues((rowMatches, rowId) => {
        const rowScore = rowMatches.reduce((a, m) => {
          let score = m.match.score;

          if (typeof m.context.cell.textSearchScaleFactor === "number") {
            score = score * m.context.cell.textSearchScaleFactor;
          }

          return a + score;
        }, 0);

        return {
          rowId,
          rowMatches,
          rowScore
        };
      })
      .value();

    //Filter row matches that have a relatively worthless row score
    const maxRowScore = Math.max(...Object.values(grouped).map(a => a.rowScore)) || 1;
    const filtered = _(grouped)
      .mapValues(({ rowId, rowMatches, rowScore }) => {
        return { rowId, rowMatches, rowScore: rowScore / maxRowScore < 0.7 ? 0 : rowScore };
      })
      .value();

    //Create bold match nodes and assign row score
    _(filtered)
      .mapValues(({ rowMatches, rowScore, rowId }) => {
        if (rowScore) {
          rowMetaByRowId[rowId].ownTextMatchScore = rowScore;
          rowMatches.forEach(({ match, context: { rowId: rowId2, cellIndex, cellStringArrayIndex } }) => {
            let node: ReactNode;
            const origStr = rowMetaByRowId[rowId2].highlightedCells[cellIndex].highlightedText[cellStringArrayIndex] as string;
            if (!match.score) {
              node = <React.Fragment key={cellStringArrayIndex}>{origStr}</React.Fragment>;
            } else {
              node = (
                <BoldMatchText key={cellStringArrayIndex} matchBitmask={match.textMatches}>
                  {origStr}
                </BoldMatchText>
              );
            }

            rowMetaByRowId[rowId2].highlightedCells[cellIndex].highlightedText[cellStringArrayIndex] = node;
          });
        }
      })
      .value();

    //text match score aggregations
    traverseAllRows(p.rows, ({ row, parentRow }) => {
      let maxSubRowScore = 0;
      if (row.subRows) {
        traverseAllRows(row.subRows, ({ row: subRow }) => {
          maxSubRowScore = Math.max(rowMetaByRowId[subRow.id].ownTextMatchScore, maxSubRowScore);
        });
      }

      let maxParentScore = 0;
      if (parentRow) {
        let thisParentRow: undefined | CoolRow = parentRow;
        while (thisParentRow) {
          //@ts-ignore
          const { parentRow: newParentRow, ownTextMatchScore: parentOwnTextMatchScore } = rowMetaByRowId[thisParentRow.id];
          maxParentScore = Math.max(parentOwnTextMatchScore, maxParentScore);
          thisParentRow = newParentRow;
        }
      }

      rowMetaByRowId[row.id].totalTextMatchScore = Math.max(
        rowMetaByRowId[row.id].ownTextMatchScore,
        maxSubRowScore,
        maxParentScore
      );
    });
  }

  return rowMetaByRowId;
}

type FlatRow = {
  row: CoolRow;
  depth: number;
  topLevelRowGroupId: string;
};

function getNewSearchTermSubRowExpansionMap(p: {
  rowMetaByRowId: RowMetaByRowId;
  flatRowsFilteredBySearchTerm: FlatRow[];
  searchTerm: string;
}) {
  return p.flatRowsFilteredBySearchTerm
    .filter(r => !!r.row.subRows)
    .reduce((acc, r) => {
      if (!p.searchTerm) {
        acc[r.row.id] = false;
      } else {
        let isExpanded = false;

        //Is expanded if any of its children have ownTextMatchScore
        traverseAllRows(r.row.subRows || [], ({ row }) => {
          if (p.rowMetaByRowId[row.id].ownTextMatchScore) {
            isExpanded = true;
            return "break-traverse";
          }
          return;
        });

        acc[r.row.id] = isExpanded;
      }
      return acc;
    }, {} as { [parentRowId in string]?: boolean });
}

function computeFlatRowsFilteredBySearchTerm(p: { rows: CoolRow[]; rowMetaByRowId: RowMetaByRowId; searchTerm: string }) {
  const baseFlattenedRows = flattenAllRows(p.rows);

  return p.searchTerm
    ? _(baseFlattenedRows)
        .filter(r => p.rowMetaByRowId[r.row.id].totalTextMatchScore > 0)
        .groupBy(a => a.topLevelRowGroupId)
        .mapValues((groupRows, rowId) => ({ groupScore: p.rowMetaByRowId[rowId].totalTextMatchScore, groupRows }))
        .toArray()
        .orderBy(a => a.groupScore, "desc")
        .map(a => a.groupRows)
        .flatten()
        .value()
    : baseFlattenedRows;
}

function traverseAllRows(
  rows: CoolRow[],
  fn: (p: { row: CoolRow; depth: number; parentRow?: CoolRow }) => void,
  meta?: { depth: number; parentRow?: CoolRow }
) {
  const rowMeta = meta ?? { depth: 0 };
  rows.forEach(row => {
    fn(Object.assign({ row }, rowMeta));
    if (row.subRows) {
      const childRowMeta = { depth: rowMeta.depth + 1, parentRow: row };
      traverseAllRows(row.subRows, fn, childRowMeta);
    }
  });
}

function flattenAllRows(rows: CoolRow[], meta?: { depth: number; topLevelRowGroupId: string }) {
  const result: { row: CoolRow; depth: number; topLevelRowGroupId: string }[] = [];
  rows.forEach(row => {
    const thisMeta = meta ?? { depth: 0, topLevelRowGroupId: row.id };

    result.push({
      row,
      ...thisMeta
    });

    if (row.subRows) {
      const subResults = flattenAllRows(row.subRows, {
        ...thisMeta,
        depth: thisMeta.depth + 1
      });
      subResults.forEach(subResult => {
        result.push(subResult);
      });
    }
  });
  return result;
}

function getColumnWidth(p: {
  cellIndex: number;
  columnPercentWidths: ({ fixedWidth: number } | number)[];
  enableRowSelection?: boolean;
}) {
  const thisColWidth = p.columnPercentWidths[p.cellIndex] || { fixedWidth: 100 };
  let sumOfFixedWidths = p.columnPercentWidths.reduce((acc, a) => {
    return typeof a === "number" ? acc : (acc as any) + a.fixedWidth;
  }, 0) as number;

  if (p.enableRowSelection) {
    sumOfFixedWidths += CHECKBOX_CELL_WIDTH;
  }

  return typeof thisColWidth === "number"
    ? `calc(${thisColWidth}% - ${sumOfFixedWidths * (thisColWidth / 100)}px)`
    : thisColWidth.fixedWidth;
}

const ROWS_PER_PAGE = [10, 25, 50, 100];
