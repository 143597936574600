import { ArrowBack } from "@material-ui/icons";
import { translate } from "@ollie-sports/i18n";
import { useHistory } from "react-router-dom";
import { useCurrentUserAdminOrgs } from "../hooks/commonDataUtils";

export function BackButton() {
  const history = useHistory();
  return (
    <div
      className="flex items-center text-blue-500 text-lg gap-2 cursor-pointer"
      onClick={() => {
        if (history.length <= 1 || !window.history.state) {
          const matchingRoute = Array.from(document.querySelectorAll("#navigation-bar a"))
            .map(a => a.getAttribute("href") || "")
            .find(a => {
              return location.pathname.includes(a);
            });

          if (matchingRoute) {
            history.push(matchingRoute);
          } else {
            history.push("/app/teams");
          }
        } else {
          history.goBack();
        }
      }}
    >
      <ArrowBack /> <div>{translate.common.Back}</div>
    </div>
  );
}
