import React, { ReactNode } from "react";
import { View, ViewProps } from "react-native-web";

export function ShadowView(p: { children: ReactNode } & ViewProps) {
  const { children, style, ...rest } = p;

  return (
    <View style={[ShadowView.defaultStyle, style]} {...rest}>
      {children}
    </View>
  );
}

ShadowView.defaultStyle = {
  zIndex: 1,
  //@ts-ignore
  boxShadow: "0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149)",
  transition: "opacity .218s",
  backgroundColor: "white"
};
