import { Account, PrettyPlayer, Team } from "@ollie-sports/models";
import { ShadowView } from "./ShadowView";
import { ProfileCircle } from "./ProfileCircle";
import { View } from "react-native-web";
import { StyledText } from "./StyledText";
import { PermissionRow } from "./PermissionRow";
import { EditButton } from "./EditButton";
import { openPlayerEditModal } from "../views/team/PlayerEditModal";
import { Team__StaffPresets, compute } from "@ollie-sports/core";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getCurrentUserAccountId } from "../hooks/commonDataUtils";
import { useOrg } from "../hooks/useOrg";

export function PlayerCard(p: { player: PrettyPlayer; playerAccount?: Account; team: Team }) {
  const teamAccount = p.playerAccount ? p.team.accounts?.[p.playerAccount.id] : undefined;
  const { org } = useOrg({ orgId: p.team.orgId || "", enabled: !!p.team.orgId });
  const isStaff = compute.isStaffOrOrgAdmin({
    accountId: getCurrentUserAccountId(),
    team: p.team,
    org: org
  });
  return (
    <ShadowView style={{ flexDirection: "row", padding: 30, borderRadius: 10 }}>
      <ProfileCircle accountInfo={p.player.derived.accountInfo} size={100} />
      <View style={{ marginLeft: 30, flex: 1 }}>
        <StyledText
          style={{ fontSize: 24, fontWeight: "bold" }}
        >{`${p.player.derived.accountInfo.firstName} ${p.player.derived.accountInfo.lastName}`}</StyledText>
        <StyledText style={{ fontSize: 16, marginTop: 4 }}>
          {p.player.player.jerseyNumber ? `#${p.player.player.jerseyNumber}` : ""}
        </StyledText>
        {teamAccount && teamAccount.staffTitle ? (
          <StyledText style={{ fontSize: 16, marginTop: 4 }}>
            {Team__StaffPresets(getCurrentLocale())[teamAccount.staffTitle].staffTitle}
          </StyledText>
        ) : null}
        {teamAccount ? <PermissionRow style={{ marginTop: "auto" }} teamAccount={teamAccount} /> : null}
      </View>
      {isStaff ? (
        <EditButton
          size={36}
          onPress={async () => {
            openPlayerEditModal({
              player: p.player,
              team: p.team,
              playerAccount: p.playerAccount
            });
          }}
        />
      ) : null}
    </ShadowView>
  );
}
