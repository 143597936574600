import _ from "lodash";
import React from "react";

export const BoldMatchText = React.memo(
  (p: { matchBitmask: bigint; children: string }): JSX.Element => {
    const bitMask = p.matchBitmask;
    const str = p.children;

    if (bitMask === BigInt(0)) {
      return <>{str}</>;
    }

    const binary = bitMask
      .toString(2)
      .split("")
      .reverse();

    const startStopBoldIndices: number[] = [];
    binary.forEach((char, i) => {
      if (char === "1") {
        const prevChar = i === 0 ? "0" : binary[i - 1];
        const nextChar = i === binary.length - 1 ? "0" : binary[i + 1];
        if (prevChar === "0") {
          startStopBoldIndices.push(i);
        }
        if (nextChar === "0") {
          startStopBoldIndices.push(i);
        }
      }
    });

    let currentStrIndex = 0;
    const textNodes: (JSX.Element | string)[] = [];
    while (startStopBoldIndices.length) {
      const nextStart = startStopBoldIndices.shift();
      const nextStop = startStopBoldIndices.shift();
      if (typeof nextStart !== "number" || typeof nextStop !== "number") {
        console.error("Something was implemented wrong somewhere the BoldMatchText algorithm...");
        break;
      }

      const leadingUnbolded = str.slice(currentStrIndex, nextStart);
      const bolded = str.slice(nextStart, nextStop + 1);
      const trailingUnbolded = startStopBoldIndices.length === 0 ? str.slice(nextStop + 1, str.length) : "";

      leadingUnbolded && textNodes.push(leadingUnbolded);
      textNodes.push(<b>{bolded}</b>);
      trailingUnbolded && textNodes.push(trailingUnbolded);
      currentStrIndex = nextStop + 1;
    }

    if (textNodes.length === 0) {
      textNodes.push(str);
    }

    return (
      <span>
        {textNodes.map((a, i) => (
          <React.Fragment key={i}>{a}</React.Fragment>
        ))}
      </span>
    );
  }
);
