import React, { ReactNode, useRef, useState } from "react";
import { Portal } from "@material-ui/core";
import { useDebouncedFn } from "../utils/hooks/useDebouncedFn";
import { useBoundingClientRect } from "../hooks/useBoundingClientRect";

export function ScrollableTooltip(p: {
  children: JSX.Element;
  enable?: boolean;
  triggerType: "hover" | "click";
  style?: React.CSSProperties;
  tooltipStyle?: React.CSSProperties;
  tooltip: ReactNode;
}) {
  const [anchorRect, setAnchorRect] = useState<null | DOMRect>(null);

  const { ref: tooltipInnerRef, rect: tooltipInnerRect } = useBoundingClientRect<HTMLDivElement>();
  const anchorRef = useRef<null | HTMLDivElement>(null);
  const showTooltipDebounced = useDebouncedFn({
    deps: [],
    fn: () => setAnchorRect(anchorRef.current?.getBoundingClientRect() || null),
    ms: 500
  });

  function getTooltipStyles() {
    if (anchorRect && tooltipInnerRect) {
      const heightAbove = anchorRect.y;
      const heightBelow = window.innerHeight - anchorRect.bottom;

      const availableSpace = heightAbove > heightBelow ? heightAbove : heightBelow;

      const top = heightAbove > heightBelow ? Math.max(4, anchorRect.top - tooltipInnerRect.height) : anchorRect.bottom;
      const height = Math.min(availableSpace, tooltipInnerRect.height);

      return {
        overflowY: tooltipInnerRect.height > height ? ("auto" as const) : ("hidden" as const),
        top,
        height
      };
    } else {
      return {};
    }
  }

  const triggerHandlers =
    p.triggerType === "hover"
      ? {
          onMouseEnter: showTooltipDebounced,
          onMouseLeave: () => {
            showTooltipDebounced.cancel();
            setAnchorRect(null);
          }
        }
      : {
          onClick: () => {
            setAnchorRect(curr => {
              if (curr) {
                return null;
              } else {
                return anchorRef.current?.getBoundingClientRect() || null;
              }
            });
          },
          onMouseLeave: () => {
            setAnchorRect(null);
          }
        };

  let cursor = p.triggerType === "click" ? "help" : "default";

  if (p.enable === false) {
    return <div style={{ userSelect: "none", cursor: "default", ...(p.style || {}) }}>{p.children}</div>;
  }

  return (
    <div style={{ userSelect: "none", cursor, ...(p.style || {}) }} ref={anchorRef} {...triggerHandlers}>
      <Portal>
        {anchorRect ? (
          <div
            style={{
              position: "absolute",
              zIndex: 60,
              opacity: !tooltipInnerRect ? 0 : undefined,
              pointerEvents: !tooltipInnerRect ? "none" : undefined,
              boxSizing: "border-box",
              left: anchorRect.left,
              maxWidth: window.innerWidth - anchorRect.left,
              ...(p.tooltipStyle || {}),
              ...getTooltipStyles()
            }}
          >
            <div style={{ boxSizing: "border-box" }} ref={tooltipInnerRef}>
              {p.tooltip}
            </div>
          </div>
        ) : null}
      </Portal>
      {p.children}
    </div>
  );
}
