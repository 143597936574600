import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useWindowDimensions, View, ViewStyle } from "react-native-web";
import { COLORS } from "../theme/COLORS";
import Logo from "./Logo";
import { StyledText } from "./StyledText";

export function ColoredHeader(p: { title: string; theme?: "default" | "short"; subtitle?: string; bgColor?: string }) {
  const theme = useTheme<Theme>();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <View
      style={[
        {
          width: "100%",
          backgroundColor: p.bgColor ?? COLORS.gold,
          flexDirection: p.theme === "short" ? "row" : "column",
          paddingHorizontal: isMobileDevice ? 16 : 32,
          alignItems: p.theme === "short" ? "flex-end" : "center",
          justifyContent: p.theme === "short" ? "flex-end" : "center",
          paddingVertical: p.theme === "short" ? 12 : 24
        }
      ]}
    >
      <Logo
        variant={isMobileDevice ? "ollie-o" : "white"}
        style={{
          ...{ fill: "white", paddingLeft: 10 },
          ...(p.theme === "short" ? { order: 1, marginLeft: "auto" } : {}),
          ...(isMobileDevice
            ? {
                width: 40,
                alignSelf: "center"
              }
            : {})
        }}
      />
      <StyledText style={{ color: COLORS.white, fontSize: isMobileDevice ? 28 : 32, margin: p.theme === "short" ? 0 : "8px 0" }}>
        {p.title}
      </StyledText>
      {p.subtitle ? (
        <StyledText
          style={{
            color: COLORS.white,
            fontSize: 18,
            paddingBottom: p.theme === "short" ? 3 : 0,
            paddingLeft: p.theme === "short" ? 32 : 0
          }}
        >
          {p.subtitle.toUpperCase()}
        </StyledText>
      ) : null}
    </View>
  );
}
