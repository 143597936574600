import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { openRootDialog } from "../services/rootDialog.service";
import { deferred } from "../utils/deferred";

export async function getDialogConfirm(p: {
  title: string;
  subtitle?: string;
  cancelText: string;
  confirmText: string;
  uniquenessId: string;
}) {
  const prom = deferred<boolean>();
  const modal = openRootDialog({
    onClose: () => {
      prom.resolve(false);
    },
    uniquenessId: p.uniquenessId,
    content: (
      <>
        <DialogTitle id="alert-dialog-title">{p.title}</DialogTitle>
        {p.subtitle ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{p.subtitle}</DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button
            onClick={() => {
              prom.resolve(false);
              modal.destroy();
            }}
            color="primary"
          >
            {p.cancelText}
          </Button>
          <Button
            onClick={() => {
              prom.resolve(true);
              modal.destroy();
            }}
            color="primary"
            autoFocus
          >
            {p.confirmText}
          </Button>
        </DialogActions>
      </>
    )
  });

  return prom;
}
