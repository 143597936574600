import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export function InfoTooltipIcon(p: { title: string; className?: string; iconClassName?: string }) {
  return (
    <InfoTooltip title={p.title}>
      <div className={clsx("px-2 cursor-pointer", p.className)}>
        <InformationCircleIcon className={twMerge("h-3 w-3", p.iconClassName)} />
      </div>
    </InfoTooltip>
  );
}

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "15px!important",
    lineHeight: "20px!important",
    backgroundColor: "rgba(97, 97, 97, 1)!important"
  },
  arrow: {
    color: "rgba(97, 97, 97, 1)!important"
  }
}));

export function InfoTooltip(p: { title: string; children: ReactElement }) {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      classes={{
        popper: "z-70" + classes.tooltip,
        tooltip: classes.tooltip,
        arrow: classes.arrow
      }}
      title={p.title}
    >
      {p.children}
    </Tooltip>
  );
}
