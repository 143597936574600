import React, { DetailedHTMLProps, ButtonHTMLAttributes, ReactNode, useRef } from "react";

//A button that requires 800ms AND any promise returned by onClick to resolve before a second click can happen
export function SafeButton(
  p: Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "onClick"> & {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>;
    timeoutMS?: number; // the number of ms that pass elapse before the button can be pressed again.
    children?: ReactNode;
  }
) {
  const { onClick, timeoutMS, ...rest } = p;

  const canClick = useRef(true);

  return (
    <button
      onClick={async e => {
        if (!canClick.current) {
          return;
        }
        canClick.current = false;
        try {
          await Promise.all([p.onClick?.(e), new Promise(res => setTimeout(res, timeoutMS || 800))]);
        } finally {
          canClick.current = true;
        }
      }}
      {...rest}
    />
  );
}
