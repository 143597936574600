import { DialogTitle, DialogContent, DialogContentText, List, ListItem, ListItemText } from "@material-ui/core";
import { COLORS } from "@ollie-sports/core";
import { openRootDialog } from "../services/rootDialog.service";
import { deferred } from "../utils/deferred";

export type DialogOptionType<T> = {
  value: T;
  label: string;
  key: string;
  bgColor?: COLORS;
  // icon?: ALL_ICONS_TYPE;
  onPress?: () => void;
};

export async function getDialogOptions<T>(p: {
  title: string;
  subtitle?: string;
  selectedValue?: any;
  isHorizontal?: boolean;
  uniquenessId: string;
  options: DialogOptionType<T>[];
}) {
  const prom = deferred<T | undefined>();
  const modal = openRootDialog({
    onClose: () => {
      prom.resolve(undefined);
    },
    uniquenessId: p.uniquenessId,
    content: (
      <>
        <DialogTitle id="alert-dialog-title" style={{ paddingLeft: 16 }}>
          {p.title}
        </DialogTitle>
        {p.subtitle ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{p.subtitle}</DialogContentText>
          </DialogContent>
        ) : null}
        <List>
          {p.options.map(option => (
            <ListItem
              button
              onClick={() => {
                prom.resolve(option.value);
                option.onPress?.();
                modal.destroy();
              }}
              key={option.key}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
        </List>
      </>
    )
  });

  return prom;
}
