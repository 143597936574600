import { Account, PrettyPlayer, Team } from "@ollie-sports/models";
import { ShadowView } from "./ShadowView";
import { ProfileCircle } from "./ProfileCircle";
import { TouchableOpacity, View } from "react-native-web";
import { StyledText } from "./StyledText";
import { PermissionRow } from "./PermissionRow";
import { Team__StaffPresets, compute } from "@ollie-sports/core";
import { getCurrentUserAccountId, useCurrentUserTeamsAndOrgs } from "../hooks/commonDataUtils";
import { EditButton } from "./EditButton";
import { openGuardianStaffEditModal } from "../views/team/GuardianStaffEditModal";
import { getCurrentLocale } from "@ollie-sports/i18n";

export function GuardianCard(p: { account: Account; team: Team }) {
  const teamAccount = p.team.accounts?.[p.account.id];
  const { data: teamsAndOrgs } = useCurrentUserTeamsAndOrgs();
  const isStaffOrOrgAdmin = compute.isStaffOrOrgAdmin({
    team: p.team,
    accountId: getCurrentUserAccountId(),
    org: teamsAndOrgs?.orgs.find(o => o.id === p.team.orgId)
  });

  return (
    <ShadowView style={{ flexDirection: "row", padding: 30, borderRadius: 10 }}>
      <ProfileCircle accountInfo={p.account} size={100} />
      <View style={{ marginLeft: 30, flex: 1 }}>
        <StyledText style={{ fontSize: 24, fontWeight: "bold" }}>{`${p.account.firstName} ${p.account.lastName}`}</StyledText>
        <StyledText style={{ fontSize: 16, marginTop: 4 }}>{p.account.email}</StyledText>
        <StyledText style={{ fontSize: 16, marginTop: 4 }}>{p.account.phoneNumber}</StyledText>
        {teamAccount && teamAccount.staffTitle ? (
          <StyledText style={{ fontSize: 16, marginTop: 4 }}>
            {Team__StaffPresets(getCurrentLocale())[teamAccount.staffTitle].staffTitle}
          </StyledText>
        ) : null}
        {teamAccount ? <PermissionRow style={{ marginTop: "auto" }} teamAccount={teamAccount} /> : null}
      </View>
      {isStaffOrOrgAdmin ? (
        <View style={{ marginLeft: 30 }}>
          <EditButton
            onPress={async () => {
              openGuardianStaffEditModal({
                account: p.account,
                team: p.team
              });
            }}
          />
        </View>
      ) : null}
    </ShadowView>
  );
}
