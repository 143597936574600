import React, { ReactNode, Fragment } from "react";

export function StyledAsteriskText(p: { children: string; asteriskClassName: string; delimiter?: string; className?: string }) {
  return (
    <span className={p.className}>
      {customizeAsterisks(p.children, p.delimiter || "*", txt => (
        <span className={p.asteriskClassName}>{txt}</span>
      ))}
    </span>
  );
}

function customizeAsterisks(str: string, delim: string, fn: (innerStr: string) => ReactNode) {
  // Split the string into parts based on asterisks
  const parts = str.split(delim);

  // Map over the parts
  const nodes = parts.map((part, index) => {
    // If the part was surrounded by asterisks (i.e., it's at an odd index), pass it to the function
    if (index % 2 === 1) {
      return <Fragment key={index}>{fn(part)}</Fragment>;
    }
    // Otherwise, return the part as a string
    else {
      return <Fragment key={index}>{part}</Fragment>;
    }
  });

  // Return the array of nodes
  return <>{nodes}</>;
}
