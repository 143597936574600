import React, { CSSProperties, ReactNode, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, ButtonBase } from "@material-ui/core";

export function ActionButtonDropdown(p: {
  children: ReactNode;
  actions: {
    key: string;
    label: () => string; //This is a function so we don't get stale data when accessing ref methods on CoolerTable and AsyncFancyTable
    onClick: () => Promise<void> | void;
  }[];
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  color?: string;
  variant?: string;
}) {
  const buttonId = useRef("a-" + Math.random() + "" + Math.random()).current;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        id={buttonId}
        color={(p.color as any) || "secondary"}
        variant={(p.variant as any) || "contained"}
        aria-haspopup="true"
        disabled={p.disabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
        style={p.style}
        className={p.className}
      >
        {p.children}
      </Button>
      <Menu
        anchorEl={() => document.getElementById(buttonId)!}
        open={isOpen}
        onClose={e => {
          //@ts-ignore
          e.preventDefault();
          //@ts-ignore
          e.stopPropagation();
          setIsOpen(false);
        }}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        {p.actions.map(a => (
          <MenuItem
            key={a.key}
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(false);
              await a.onClick();
            }}
          >
            {a.label()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
