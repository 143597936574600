import { TouchableOpacity, View } from "react-native-web";
import { StyledText } from "./StyledText";
import { translate } from "@ollie-sports/i18n";
import { COLORS } from "@ollie-sports/core";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

export function EditButton(p: { onPress: () => Promise<void>; size?: number }) {
  const size = p.size || 20;
  return (
    <div style={{ height: size, width: size }} className="hover:opacity-70">
      <TouchableOpacity style={{ flex: 1 }} onPress={p.onPress}>
        <PencilSquareIcon color={COLORS.green} />
      </TouchableOpacity>
    </div>
  );
}
