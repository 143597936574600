import React, { FC } from "react";

interface LogoProps {
  [key: string]: any;
}

const LogoWithName: FC<LogoProps> = props => {
  return <img alt="Logo" style={{width: 80}} src="/static/logo-with-name.png" {...props} />;
};

export default LogoWithName;
