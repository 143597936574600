import {
  ChatBubbleLeftIcon,
  CurrencyDollarIcon,
  MagnifyingGlassCircleIcon,
  UserCircleIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import { ORG_PERMISSIONS, Org__Account } from "@ollie-sports/models";
import { View } from "react-native-web";

export function OrgPermissionRow(p: { orgAccount: Org__Account; style?: any }) {
  return (
    <View style={[{ flexDirection: "row" }, p.style]}>
      <OrgPermissionIcon
        permission={ORG_PERMISSIONS.manageOrgAccounts}
        hasPermission={!!p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgAccounts]}
        hideMarginLeft={true}
      />
      <OrgPermissionIcon
        permission={ORG_PERMISSIONS.manageFinances}
        hasPermission={!!p.orgAccount.permissions[ORG_PERMISSIONS.manageFinances]}
        hideMarginLeft={!p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgAccounts]}
      />
      <OrgPermissionIcon
        permission={ORG_PERMISSIONS.viewFinances}
        hasPermission={
          !!p.orgAccount.permissions[ORG_PERMISSIONS.viewFinances] || !!p.orgAccount.permissions[ORG_PERMISSIONS.manageFinances]
        }
        hideMarginLeft={
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgAccounts] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageFinances]
        }
      />
      <OrgPermissionIcon
        permission={ORG_PERMISSIONS.manageOrgChat}
        hasPermission={!!p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgChat]}
        hideMarginLeft={
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgAccounts] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageFinances] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.viewFinances]
        }
      />
      <OrgPermissionIcon
        permission={ORG_PERMISSIONS.playerNotes}
        hasPermission={!!p.orgAccount.permissions[ORG_PERMISSIONS.playerNotes]}
        hideMarginLeft={
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgAccounts] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageFinances] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.viewFinances] &&
          !p.orgAccount.permissions[ORG_PERMISSIONS.manageOrgChat]
        }
      />
    </View>
  );
}

function OrgPermissionIcon(p: { permission: ORG_PERMISSIONS; hasPermission: boolean; hideMarginLeft?: boolean }) {
  if (!p.hasPermission) {
    return null;
  }
  const icon =
    p.permission === ORG_PERMISSIONS.manageOrgAccounts ? (
      <UserCircleIcon />
    ) : p.permission === ORG_PERMISSIONS.manageFinances ? (
      <CurrencyDollarIcon />
    ) : p.permission === ORG_PERMISSIONS.viewFinances ? (
      <MagnifyingGlassCircleIcon />
    ) : p.permission === ORG_PERMISSIONS.manageOrgChat ? (
      <ChatBubbleLeftIcon />
    ) : p.permission === ORG_PERMISSIONS.playerNotes ? (
      <PencilSquareIcon />
    ) : null;
  return <View style={{ width: 20, height: 20, marginLeft: p.hideMarginLeft ? 0 : 2 }}>{icon}</View>;
}
