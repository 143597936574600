import { CSSProperties, useState } from "react";
import { LoadingCircle } from "./LoadingCircle";
import { twMerge } from "tailwind-merge";

export function StyledButton(p: {
  className?: string;
  text: string;
  style?: CSSProperties;
  onClick: () => Promise<void>;
  disabled?: boolean;
  variant?: "red" | "gray" | "blue-outline";
  isLoading?: boolean;
}) {
  const [isSaving, setIsSaving] = useState(false);
  const isDisabled = p.disabled || p.isLoading || isSaving;
  return (
    <button
      style={p.style}
      onClick={async () => {
        setIsSaving(true);
        try {
          await p.onClick();
        } finally {
          setIsSaving(false);
        }
      }}
      disabled={isDisabled}
      className={twMerge(
        "inline-flex items-center justify-center text-white rounded h-10 px-2 py-2 w-48",
        isDisabled && !(isSaving || p.isLoading) && "opacity-40",
        p.variant === "red"
          ? " bg-red-500 hover:bg-red-600 focus:bg-red-700"
          : p.variant === "gray"
          ? " bg-gray-500 hover:bg-gray-600 focus:bg-gray-700"
          : p.variant === "blue-outline"
          ? " bg-white border-blue-600 border text-blue-600 hover:bg-gray-100 focus:bg-gray-200"
          : " bg-primary hover:bg-blue-600 focus:bg-blue-700",
        p.className
      )}
    >
      {p.isLoading || isSaving ? <LoadingCircle className="ml-3" /> : p.text}
    </button>
  );
}
