import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode, useRef } from "react";
import { Theme } from "../theme";

const useStyles = makeStyles((theme: Theme) => {
  return {
    wrapper: {
      display: "inline-block",
      "& input:focus + label, label:hover": {
        backgroundColor: "#DDD"
      }
    },
    label: {
      cursor: "pointer",
      backgroundColor: "#FFF",
      transition: "background-color 200ms linear",
      borderRadius: 4,
      padding: 4
    }
  };
});

export const FileInput = React.forwardRef(
  (p: React.InputHTMLAttributes<HTMLInputElement> & { contents: ReactNode }, ref: any) => {
    const classes = useStyles();
    const { type, name, id, className, style, ...rest } = p;
    const uniqueId = useRef(Math.random().toString() + Math.random().toString());

    return (
      <div className={classes.wrapper}>
        <input
          type="file"
          name={uniqueId.current}
          id={uniqueId.current}
          style={{
            width: 0.1,
            height: 0.1,
            opacity: 0,
            overflow: "hidden",
            position: "absolute",
            zIndex: -1
          }}
          {...rest}
        />
        <label ref={ref} className={clsx(classes.label, className)} style={style} htmlFor={uniqueId.current}>
          {p.contents}
        </label>
      </div>
    );
  }
);
