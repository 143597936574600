import React from "react";
import { View } from "react-native-web";
import clsx from "clsx";

<span />;

export interface TextButtonProps {
  onPress: () => void;
  iconElm: JSX.Element;
  text: string;
  className?: string;
  textClassName?: string;
  textProps?: Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>, "className">;
  reversed?: boolean;
  disabled?: boolean;
}

export function TextButton(p: TextButtonProps) {
  const { text, iconElm, reversed, disabled } = p;

  const thisIcon = <View>{iconElm}</View>;

  return (
    <button
      className={clsx("flex items-center py-1", p.disabled && "opacity-50", p.className)}
      disabled={disabled}
      onClick={p.onPress}
    >
      {!reversed && thisIcon}
      <span className={clsx(p.reversed ? "mr-2" : "ml-2", p.textClassName)} {...(p.textProps || {})}>
        {text}
      </span>
      {reversed && thisIcon}
    </button>
  );
}
