import React from "react";
import { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useGlobalState } from "../services/global-state.service";

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const state = useGlobalState();

  if (state.auth.isLoggedIn) {
    return <Redirect to="/app/teams" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
