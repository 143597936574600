import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps } from "@material-ui/pickers";

type Props = DateTimePickerProps &
  DatePickerProps & {
    mode: "date-time" | "date";
  };

export function DateTimePickerDynamic(p: Props) {
  return p.mode === "date" ? <DatePicker {...p} /> : <DateTimePicker {...p} />;
}
