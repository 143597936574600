import React, { FC } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ReactQuill from "react-quill";
import Quill from "quill";
import { makeStyles } from "@material-ui/core";
import { Theme } from "src/theme";

const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

// NOTE: At this moment, this ReactQuill does not export
// the types for props and we cannot extend them
interface QuillEditorProps {
  className?: string;
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .ql-toolbar": {
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& .ql-picker-label:hover": {
        color: theme.palette.secondary.dark
      },
      "& .ql-picker-label.ql-active": {
        color: theme.palette.secondary.dark
      },
      "& .ql-picker-item:hover": {
        color: theme.palette.secondary.dark
      },
      "& .ql-picker-item.ql-selected": {
        color: theme.palette.secondary.dark
      },
      "& button:hover": {
        color: theme.palette.secondary.dark,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.dark
        }
      },
      "& button:focus": {
        color: theme.palette.secondary.dark,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.dark
        }
      },
      "& button.ql-active": {
        "& .ql-stroke": {
          stroke: theme.palette.secondary.dark
        }
      },
      "& .ql-stroke": {
        stroke: theme.palette.text.primary
      },
      "& .ql-picker": {
        color: theme.palette.text.primary
      },
      "& .ql-picker-options": {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: "none",
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius
      }
    },
    "& .ql-container": {
      border: "none",
      flex: 1,
      flexDirection: "column",
      display: "flex",
      "& .ql-editor": {
        flex: 1,
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        "& ol, ul": {
          padding: "1em 0 1em 0.5em"
        },
        "&.ql-blank::before": {
          color: theme.palette.text.secondary
        }
      }
    }
  }
}));

const QuillEditor: FC<QuillEditorProps & { controlsTabIndex?: number }> = ({
  className,
  tabIndex,
  controlsTabIndex,
  ...rest
}) => {
  const classes = useStyles();

  return (
    // @ts-ignore
    <ReactQuill
      ref={ref => {
        if (ref) {
          const module = ref?.getEditor()?.getModule("keyboard");

          if (module) {
            delete module.bindings["9"]; //Make it impossible to tab inside the editor. This is what gmail does.
          }

          if (controlsTabIndex) {
            document
              .querySelectorAll(".ql-toolbar .ql-picker-label, .ql-toolbar button")
              .forEach(elm => elm.setAttribute("tabindex", controlsTabIndex.toString()));
          }

          //Maybe a bug in react-quill? Setting tabIndex on the component itself applies the tab index to the wrong element
          if (tabIndex) {
            document.querySelectorAll(".ql-editor")?.[0]?.setAttribute("tabindex", tabIndex.toString());
          }
        }
      }}
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
};

QuillEditor.propTypes = {
  className: PropTypes.string
};

export default QuillEditor;
